import { useElementVisibility, useIntersectionObserver } from "@vueuse/core";
import axios from "axios";
import { jwtDecode, type JwtPayload } from "jwt-decode";
import type { UseFetchOptions } from "nuxt/app";
import { z, type ZodRawShape } from "zod";
import { useMegaMenuStore } from "~/components/layout/MegaMenu/store";
import type { User } from "~/entities/User";
import { useGlobalStore } from "~/stores/global";
import type { HttpResponse } from "~/types/general";
import Application from "~/utils/config";

/**
 * A helper method to catch the active localeName from useLocale hook.
 *
 * @returns Active localeName (E.G: en-GB | fr-FR | ar-MA | etc...)
 */
export const localeName = () => useLocaleFields().lang;

/**
 * A helper method to translate the language in stores file.
 *
 * @returns string translated message
 */
export const useTranslateStore = (data: string) => {
  const nuxtApp = useNuxtApp();
  return nuxtApp.$i18n.t(data);
};

export const switchBlogLocale = (isBlogPath: boolean = false) => {
  if (isBlogPath) {
    return useGlobalStore().siteLangCode;
  }
  return useGlobalStore().siteCurrentLocale ?? "";
};

export const localeUrlQuery = (path: Object, locale: string) => {
  const localeRoute = useLocaleRoute();

  return localeRoute(path, locale ?? useGlobalStore().siteCurrentLocale);
};

export const localeUri = (strPath: string) => {
  const localeRoute = useLocaleRoute();
  if (strPath && strPath.search("/blog") == 0) {
    return localeRoute(strPath, useGlobalStore().siteLangCode);
  }
  return localeRoute(strPath, useGlobalStore().siteCurrentLocale);
};

export type UseFetchSafelyOptions = UseFetchOptions<any> &
  Partial<{
    validationSchema: ReturnType<typeof useZod>;
    useErrorBoundary: boolean;
  }>;

/**
 * Custom composable hook for fetching data from an API endpoint.
 *
 * @template TData - The expected data type of the response.
 * @param string Endpoint url.
 * @param options useFetch second paramaters (Options).
 * @param schema Zod validation schema, should be generated from `useZod`.
 * @returns The processed response data.
 */
export const useFetchSafely = async <TData>(
  url: string | (() => string),
  options?: UseFetchSafelyOptions
) => {
  const { validationSchema, useErrorBoundary = true, ...opts } = options ?? {};

  const authToken = useCookie(Application.Cookies.authSession).value;

  // Make the API request using the `useFetch` composable with the provided arguments.
  const response = await useFetch(url, {
    // Set the base URL for the API request.
    baseURL: `${useRuntimeConfig().public.apiBase}/${
      useGlobalStore().siteCurrentLocale
    }`,

    // Specify the HTTP method as "GET".
    method: "GET",

    // Append authorization headers and allow overrides.
    headers: {
      Authorization: `Bearer ${authToken}`,
      ...opts.headers,
    },

    // Pass the remaining arguments to `useFetch`.
    ...opts,
  });

  // Add an automatic error guard.
  if (useErrorBoundary && response.error.value)
    throw createError({
      fatal: true,
      ...response.error.value,
    });

  // If validation is provided, parse the response data using the provided validation schema.
  if (validationSchema) validationSchema.parse(response.data.value ?? {});

  // Return the processed response data.
  return response as ReturnType<typeof useFetch<TData>>;
};

/**
 * Hook to create an Axios instance with a base URL derived from the runtime configuration's public API base and the current site locale.
 * @returns The configured Axios instance.
 */
export function useAxios() {
  const authorizationToken = useCookie(Application.Cookies.authSession).value;

  const instance = axios.create({
    baseURL: `${useRuntimeConfig().public.apiBase}/${
      useGlobalStore().siteCurrentLocale
    }`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authorizationToken}`,
    },
  });

  return instance;
}

type UseMutationController = (...args: any[]) => Promise<any>;
export interface UseMutationOptions<
  TError extends Error,
  TController extends UseMutationController
> {
  mutationFn: TController;
  onSuccess?(data: Awaited<ReturnType<TController>>): void;
  onError?(error: TError): void;
  onSettled?(
    data: Awaited<ReturnType<TController>> | undefined,
    error: TError | undefined
  ): void;
  executionInterval?: number;
  executionIntervalUnit?: "milliseconds" | "seconds" | "minutes";
}

/**
 * Defines a hook for handling mutations with the specified controller function.
 *
 * @template TError - The type of error that can be thrown during the mutation process.
 * @template TController - The type of the mutation controller function.
 *
 * @param {UseMutationOptions<TError, TController>} options - The options object containing the mutation function and optional callbacks.
 * @param {TController} options.mutationFn - The mutation controller function to be executed.
 * @param {(data: Awaited<ReturnType<TController>>) => void} [options.onSuccess] - Callback function to handle successful mutation results.
 * @param {(error: TError) => void} [options.onError] - Callback function to handle errors during mutation.
 * @param {(data: Awaited<ReturnType<TController>> | undefined, error: TError | undefined) => void} [options.onSettled] - Callback function to handle the final state after mutation.
 *
 * @returns {{ pending: Ref<boolean>, error: Ref<Error | undefined>, data: Ref<Awaited<ReturnType<TController>> | undefined>, mutate: (...args: Parameters<TController>) => Promise<void> }} - An object containing reactive refs and a mutation function.
 */
export function useMutation<
  TError extends Error,
  TController extends UseMutationController
>({
  mutationFn,
  onError,
  onSettled,
  onSuccess,
  executionInterval = 0,
  executionIntervalUnit = "milliseconds"
}: UseMutationOptions<TError, TController>) {
  const lastSuccessfulExecution = ref<Date>();
  const pending = ref<boolean>(false);
  const error = ref<Error>();
  const canExecute = ref<boolean>(true);
  const data = ref<Awaited<ReturnType<TController>>>();
  const success = ref<boolean>(false);

  const toMilliseconds = (value: number) => {
    switch (executionIntervalUnit) {
      case "milliseconds":
        return value;
      case "seconds":
        return value * 1000;
      case "minutes":
        return value * 60 * 1000;
      default:
        throw new Error("Invalid time unit specified");
    }
  }
  

  const mutate = async (...args: Parameters<TController>) => {
    canExecute.value = false;
    pending.value = true;
    try {
      data.value = await mutationFn.apply(data, args).then((res) => {
        success.value = true;
        onSuccess?.(res);
        lastSuccessfulExecution.value = new Date();
        
        setTimeout(() => {
          canExecute.value = true;
        }, toMilliseconds(executionInterval));
        return res;
      });
    } catch (err) {
      error.value = err as Error;
      onError?.(err as TError);
      canExecute.value = true;
    } finally {
      pending.value = false;
    }

    onSettled?.(data.value, error.value as TError);
  };

  return {
    pending,
    error,
    success,
    data,
    mutate,
    lastSuccessfulExecution,
    canExecute
  } as const;
}

/**
 * Asynchronously retrieves the user's profile details including the login status, profile information,
 * authentication token, and decoded JWT payload.
 * 
 * @returns A promise that resolves to an object containing:
 * - `loggedIn`: A boolean indicating if the user is logged in.
 * - `profile`: An object representing the user's profile details as per the `User.ProfileDetails` interface, or `null` if not available.
 * - `token`: A string representing the authentication token, or `null` if not available.
 * - `jwt`: An object representing the decoded JWT payload, or `null` if not available.
 */
export async function useUserSession(): Promise<{
  loggedIn: boolean;
  profile: User.ProfileDetails | null;
  token: string | null;
  jwt: JwtPayload | null;
}> {
  const token = useCookie(Application.Cookies.authSession).value;
  if (!token)
    return {
      loggedIn: false,
      jwt: null,
      profile: null,
      token: token ?? null,
    };

  const jwt = jwtDecode(token);
  const { data: profileDetailsResponse } =
    await useFetchSafely<HttpResponse<User.ProfileDetails> | null>(
      "/profile/details",
      {
        method: "PUT",
        useErrorBoundary: false,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

  const profile = profileDetailsResponse.value?.data;
  if (!profile)
    return {
      loggedIn: true,
      jwt,
      profile: null,
      token,
    };

  return {
    loggedIn: true,
    jwt,
    profile,
    token,
  };
}

export async function logout() {
  useCookie(Application.Cookies.authSession).value = null;
  return navigateTo(localeUri('/account/login'));
}

/**
 * Awaits a promise to be resolved and then returns.
 *
 * **PS: In addition we could pass a callback function to keep our code cleaner when trying to do some side effect processes.**
 *
 * @param state A promise or function that returns a promise
 * @param cb A callback function which will be called when the promise is resolved
 * @returns Returns data from resolved promise
 */
export const usePendingState = async <T>(
  state: Promise<T> | (() => Promise<T>),
  cb?: (data: T) => void
) => {
  const res = await (typeof state === "function" ? state() : state);
  cb?.(res);
  return res;
};

/**
 * Performs an effect and returns a reactive result based on dependency changes.
 *
 * @template T - The type of the result value.
 * @param {function(): T} cb - The callback function that computes the result value.
 * @param {Ref[]} deps - An array of reactive references to watch for dependency changes.
 * @returns {Ref<T | undefined>} - A reactive reference to the computed result value.
 * @example
  const dependency1 = ref<number>(159);
  const dependency2 = ref<number>(125);
  const sum = useEffect((document) => {
    return depency1.value + dependecy2.value;
  }, [dependency1, dependency2]);
 */
export const useEffect = <T>(cb: () => T, deps: Ref[]): Ref<T | undefined> => {
  const result = ref<T>();

  const updateResult = () => {
    try {
      result.value = cb();
    } catch {
      result.value = undefined;
    }
  };

  watch(deps, updateResult);
  return result;
};

/**
 * Custom hook to handle nested slots.
 *
 * @returns An object containing the slots, a flag indicating if there are slots present,
 * and a function to populate props for the nested slots.
 */
export const useNestedSlots = () => {
  const _slots = useSlots();
  const slots = _slots.default?.() ?? [];

  const populateProps = <T extends (typeof slots)[number]["props"]>(props: T) =>
    slots?.map((slot) => {
      const newProps = { ...props, ...slot.props };
      slot.props = newProps;
      return slots;
    });

  return {
    slots,
    hasSlots: Boolean(slots.length > 0),
    populateProps,
  };
};

/**
 * Allow us to use locale fields for checks easily.
 *
 * @returns `{ lang: string; country: string; isAustralia: boolean }`
 */
export const useLocaleFields = () => {
  const { siteCurrentLocale, siteCountry } = useGlobalStore();

  return {
    lang: siteCurrentLocale,
    country: siteCountry,
    isAustralia: siteCurrentLocale === "en-au",
  };
};

export const useCountrySpecificLocale = () => {
  const { locale } = useI18n();
  return {
    //TODO CHANGE THIS TO EN-AE
    isUae: locale.value === "en-gb",
  };
};

export const useCheckVisibility = (
  target: HTMLElement,
  ctaBlock: HTMLElement
) => {
  const isVisible = ref(false);
  useIntersectionObserver(
    target,
    ([{ isIntersecting }]) => {
      isVisible.value = isIntersecting;
    },
    {
      threshold: 0,
    }
  );

  return {
    isCtaBlockVisible: useElementVisibility(ctaBlock),
    isVisible: isVisible,
  };
};

interface SeoData {
  title: string;
  meta_title: string;
  meta_description: string;
  canonical_link: string;
  other_page_canonical_link: string | null;
  hreflang: Array<{ site: string }> | [];
}

export const useMetaTitles = (data: SeoData): void => {
  const runtimeConfig = useRuntimeConfig();
  const { locale } = useI18n();
  const route = useRoute();
  const canonicalLink =
    data.canonical_link === "same"
      ? runtimeConfig.public.i18n?.baseUrl + route.path
      : data.canonical_link === "other"
      ? data.other_page_canonical_link
      : "";
  const htmllink = data.hreflang
    ?.filter((entry) => entry.slug !== undefined)
    .map((entry) => entry.site)
    .concat(locale.value);
  
  const externalLinks = data.hreflang
  ?.filter((entry) => entry.url !== undefined)
  .map((entry) => ({
    rel: 'alternate',
    href: entry.url,
    hreflang: entry.site,
  }));

  const i18nHead = useLocaleHead({
    addSeoAttributes: true,
  });

  const canonicalAttrib = i18nHead.value.link
    ?.map((data) => {
      if (data.rel === "canonical" && canonicalLink !== "") {
        return {
          rel: "canonical",
          href: canonicalLink,
        };
      }
      if (
        data.rel === "alternate" &&
        htmllink.length > 0 &&
        htmllink.includes(data.hreflang)
      ) {
        return { ...data };
      }
      return {};
    })
    .filter((data) => Object.keys(data).length !== 0);

  const metaArrib = [
    { name: "title", content: data.meta_title },
    { name: "description", content: data.meta_description },
  ].filter((data) => data.content !== "" && data.content != null);

  useHead({
    link: [...(canonicalAttrib || []), ...(externalLinks || [])],
    title: data.meta_title,
    meta: metaArrib,
  });
};

interface BlogSeoData {
  title: string;
  description: string;
  canonical: string;
}

export const useBlogMetaTitles = (data: BlogSeoData): void => {
  const canonicalLink = data?.canonical ?? "";
  const i18nHead = useLocaleHead({
    addSeoAttributes: true,
  });

  const canonicalAttrib = i18nHead.value.link
    ?.map((data) => {
      if (data.rel === "canonical" && canonicalLink != "") {
        return { rel: "canonical", href: canonicalLink };
      }
      if (data.rel === "alternate") {
        return {};
      }
      return {};
    })
    .filter((data) => Object.keys(data).length !== 0);
  const metaArrib = [
    { name: "title", content: data?.title },
    { name: "description", content: data?.description },
  ].filter((data) => data.content !== "");

  useHead({
    link: [...(canonicalAttrib || [])],
    title: data?.title,
    meta: metaArrib,
  });
};

/**
 * @template T - The shape of the schema that the callback function returns.
 * @param {function(z: typeof z): T} cb - A callback function that receives the Zod library and returns a schema shape.
 * @returns {Object} An object containing the Zod `schema` and `parse` function for data validation and parsing.
 * @example
 * // Import the hook and the Zod library
 * import { useZod } from './useZod';
 * import * as z from 'zod';
 *
 * // Create a Vue component
 * export default {
 *   name: 'ZodComponent',
 *   setup() {
 *     // Define a Zod schema using the hook
 *     const { schema, parse } = useZod((zod) => ({
 *       name: zod.string(),
 *       age: zod.number().min(0),
 *     }));
 *
 *     // Example usage of the parse function
 *     try {
 *       const data = parse({ name: 'Alice', age: 25 });
 *       console.log('Parsed data:', data);
 *     } catch (error) {
 *       console.error('Error parsing data:', error);
 *     }
 *
 *     return {
 *       schema,
 *       parse,
 *     };
 *   }
 * }
 */
export const useZod = <T extends ZodRawShape>(cb: (zod: typeof z) => T) => {
  const schema = z.object(cb(z));

  const parse = (data: z.infer<typeof schema>, fatal = true) => {
    const result = schema.safeParse(data);
    if (!result.success)
      throw createError({
        statusCode: 400,
        message: result.error.message,
        cause: "Data does not match required criterias.",
        fatal,
      });

    return result.data;
  };

  return {
    schema,
    parse,
  };
};

/**
 * @typedef {Object} NativePrinterOptions - Options for configuring the native printer view mode.
 * @property {"takeover" | "float" | "break"} mode - The mode in which the native printer view should be displayed.
 */
export interface NativePrinterOptions {
  mode?: "takeover" | "float" | "break";
}

/**
 * Custom Vue 3 composition function for utilizing a native printer view.
 * @param {NativePrinterOptions} options - Options for configuring the native printer view.
 * @returns {Object} An object containing the `print` function to trigger printing with native view.
 * @template {NativePrinterOptions} OptionsType
 * @example
 * // Import the hook
 * import { useNativePrinter } from './useNativePrinter';
 *
 * // Use the native printer hook
 *  const printer = useNativePrinter({ mode: 'float' });
 *
 *  // Define a function to trigger printing
 *  const handlePrint = () => {
 *    // Get the element you want to print
 *    const printableElement = document.getElementById('element-to-print');
 *    if (printableElement) {
 *      // Use the print function from the hook
 *      printer.print(printableElement);
 *    }
 *  };
 */
export const useNativePrinter = ({ mode = "float" }: NativePrinterOptions) => {
  const _generateView = () => {
    const className = `native-print__view`;

    const styles: Record<NonNullable<NativePrinterOptions["mode"]>, string> = {
      float: String(`
        .${className} {
          position: fixed;
          z-index: 99999999999;
          left: 0;
          top: 0;
          width: 100dvw;
          height: 100dvh;
          background-color: #FFFFFF;
          overflow:hidden;
        }
      `),

      takeover: String(`
        .${className} {
          position: relative;
          height: 100dvh;
          background-color: #FFFFFF;
          overflow:hidden;
        }

        #__nuxt {
          display: none !important;
        }
      `),

      break: String(`
        .${className} {
          position: relative;
          height: 100dvh;
          background-color: #FFFFFF;
          overflow:hidden;
        }
      `),
    };

    const _generateStyle = () => {
      const styleTag = document.createElement("style");
      styleTag.lang = "text/css";
      styleTag.textContent = styles[mode]
        .concat(
          `
          @media only screen {
            .${className} {
              display: none;
            }
          }
          `
        )
        .replaceAll(/\n|\r| {2,}/g, "");

      return styleTag;
    };

    const _generateContainer = () => {
      const containerTag = document.createElement("div");
      containerTag.className = className;

      const style = _generateStyle();
      containerTag.appendChild(style);

      return containerTag;
    };

    return _generateContainer();
  };

  const _createView = () => {
    const view = Object.assign(_generateView(), {
      attachElement: (element: HTMLElement) => {
        const node = element.cloneNode(true) as HTMLElement;

        const wrapper = document.createElement("div");
        wrapper.innerHTML = node.outerHTML;
        wrapper.setAttribute("aria-labelledby", "printable pdf preview");

        view.appendChild(wrapper);
      },
      dispose: () => {
        view.remove();
      },
      mount: () => {
        document.body.appendChild(view);
        if (mode === "break") document.querySelector("#__nuxt")?.remove();
      },
    });

    return view;
  };

  const print = (element: HTMLElement) => {
    const view = _createView();
    view.attachElement(element);
    view.mount();

    window.print();
    view.dispose();
  };

  return {
    print,
  };
};

export const getRatingScoreLabel = (rating: number) => {
  return useTranslateStore(`reviewBlock.score${Math.round(rating)}`);
};

export const generateHelpAdviceURL = (item: {
  type: string;
  parent: { slug: any };
  primary_category: { parent: { slug: any }; slug: any };
  slug: string;
}) => {
  let category = "";
  if (item.type !== "conditions") {
    if (item.parent) {
      category += `${item.parent.slug}/`;
    }
  } else {
    if (item.primary_category && item.primary_category.parent) {
      category += `${item.primary_category.parent.slug}/`;
    }
    if (item.primary_category) {
      category += `${item.primary_category.slug}/`;
    }
  }

  return "/help-and-advice/" + category + item.slug;
};

export const handleMenuRedirect = async (event: PointerEvent) => {
  // close megamenu
  const megamenuElement = document.querySelector<HTMLElement>(".megamenu");
  megamenuElement?.removeAttribute("data-is-active");

  const megamenuBackDropElement =
    document.querySelector<HTMLElement>(".megamenu-backdrop");
  megamenuBackDropElement?.removeAttribute("data-is-active");

  // revoke active megamenu anchor
  const store = useMegaMenuStore();
  store.setActiveSection(undefined);

  // redirect
  return navigateTo({
    path: (event.currentTarget as HTMLAnchorElement).pathname,
    force: true,
  });
};

/**
 * Trims the locale string from the given pathname.
 *
 * @param pathname - Pathname
 * @returns {string}
 */
export function trimLocaleFromPathname(pathname: string): string {
  const locale = switchBlogLocale();
  const regexp = new RegExp(`\/${locale}`, "i");
  return pathname.replace(regexp, "");
}

export async function findLocaleRouteMatch(_routeArray: any) {
  const getLocalesData = await getLocales();
  const _localeArray = Object.values(getLocalesData).map((data) =>
    data.languages.map((langCode) => langCode.code)
  );

  // Flatten array _localeArray
  const flatLocaleArray = _localeArray.flat();

  // Check for matches
  return _routeArray
    .map((route: string) => route.replace("/", "")) // Remove leading '/' from each route
    .some((trimmedRoute: any) => flatLocaleArray.includes(trimmedRoute)); // Check if any trimmed route exists in the flattened locale array
}
