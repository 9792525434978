<template>
  <ol
    v-if="breadcrumbs.length > 0"
    class="relative mx-auto justify-center border-b border-b-primary-light bg-[#F7F7F7] px-5 py-3 text-sm sm:bg-transparent md:mt-3 md:max-w-7xl md:border-b-transparent"
    id="breadcrumbs"
  >
    <li
      v-for="(item, i) in breadcrumbs"
      :key="i"
      class="text-dark-blue text-sm last:font-bold inline after:inline after:p-2 last:after:content-none"
      :class="(i === 0 ? '' : `after:content-['/']`)"
    >
    <NuxtLinkLocale v-if="item.to && item.to.search('/blog') == 0" :locale="switchBlogLocale(true)" :to="(item.to)" >
        <span> {{ item.title }} </span>
      </NuxtLinkLocale>
      <NuxtLinkLocale v-else-if="item.to && item.to.search('/blog') != 0" :locale="switchBlogLocale()" :to="(item.to)" >
        <span> {{ item.title }} </span>
      </NuxtLinkLocale>
   
      <span v-else> {{ item.title }} </span>
    </li>
  </ol>
</template>

<script setup lang="ts">

interface BreadCrumbsProps {
  data: Array<{
    title: string;
    to: string;
  }>
}

const props = defineProps<BreadCrumbsProps>();

const breadcrumbs = ref<BreadCrumbsProps['data']>([]);
const home = [{ to: "/", title: useTranslateStore("breadcrumb.home") }];

if (props.data.length > 0) {
  breadcrumbs.value = [...home, ...props.data];

  generateSchema("breadcrumbs", breadcrumbs.value);
}
</script>

<style scoped>
ol {
  list-style: none;
}
</style>
