const Cookies = {
  prefix: "b2k_",

  get authSession() {
    return this.prefix.concat("session");
  },
};

const Application = {
  Cookies,
};

export default Application;
