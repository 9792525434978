import validate from "/data/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45setup_45global from "/data/middleware/01.setup.global.ts";
import _02_45page_45transition_45global from "/data/middleware/02.page-transition.global.ts";
import _03_45auth_45global from "/data/middleware/03.auth.global.ts";
import manifest_45route_45rule from "/data/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45setup_45global,
  _02_45page_45transition_45global,
  _03_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}