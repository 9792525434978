import { default as awardsGZE03KY3bbMeta } from "/data/pages/about-us/awards.vue?macro=true";
import { default as in_45the_45media3XkjronnXvMeta } from "/data/pages/about-us/in-the-media.vue?macro=true";
import { default as indexw4ONCROkyTMeta } from "/data/pages/about-us/index.vue?macro=true";
import { default as indexfo5VOb1sbeMeta } from "/data/pages/account/[page]/index.vue?macro=true";
import { default as account_45cardv0ubeQnsOBMeta } from "/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue?macro=true";
import { default as indexlWWDf54wTDMeta } from "/data/pages/account/[page]/protected/views/account-settings/index.vue?macro=true";
import { default as delete_45accountFWElTUQQYpMeta } from "/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue?macro=true";
import { default as delivery_45addressp3W0d1NjonMeta } from "/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue?macro=true";
import { default as emaildPRsz9lcXNMeta } from "/data/pages/account/[page]/protected/views/account-settings/sections/email.vue?macro=true";
import { default as indexopwKIz2VbqMeta } from "/data/pages/account/[page]/protected/views/account-settings/sections/index.ts?macro=true";
import { default as password7pYgeVy1fMMeta } from "/data/pages/account/[page]/protected/views/account-settings/sections/password.vue?macro=true";
import { default as personal_45detailsqBwHqe4JkdMeta } from "/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue?macro=true";
import { default as phone_45numberElsv4wwgmBMeta } from "/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue?macro=true";
import { default as device_45signoutdsr9bcVHE4Meta } from "/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue?macro=true";
import { default as indexRd1ZMxNn5bMeta } from "/data/pages/account/[page]/protected/views/order-history/index.vue?macro=true";
import { default as device_45signoutEa8ed5ZbwHMeta } from "/data/pages/account/[page]/protected/views/security/components/device-signout.vue?macro=true";
import { default as indexz1wzNUfIjJMeta } from "/data/pages/account/[page]/protected/views/security/index.vue?macro=true";
import { default as auth_45containerUm5Lrhj0IwMeta } from "/data/pages/account/[page]/public/components/auth-container.vue?macro=true";
import { default as auth_45windowzXElIeYSiiMeta } from "/data/pages/account/[page]/public/components/auth-window.vue?macro=true";
import { default as page_45headerwWpL8Krq7tMeta } from "/data/pages/account/[page]/public/components/page-header.vue?macro=true";
import { default as loginkkIGVYMbBJMeta } from "/data/pages/account/[page]/public/views/login.vue?macro=true";
import { default as password_45resetLMIFhG83YJMeta } from "/data/pages/account/[page]/public/views/password-reset.vue?macro=true";
import { default as signupU5jtSSJqIRMeta } from "/data/pages/account/[page]/public/views/signup.vue?macro=true";
import { default as dashboard_45cardy8jkOjeVcjMeta } from "/data/pages/account/components/dashboard-card.vue?macro=true";
import { default as indexX6Tsj9kTOCMeta } from "/data/pages/account/index.vue?macro=true";
import { default as _91slug_93cXioxiEr7bMeta } from "/data/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93BF43XaeoiDMeta } from "/data/pages/blog/authors/[slug].vue?macro=true";
import { default as indexjKH6jSfTSdMeta } from "/data/pages/blog/authors/index.vue?macro=true";
import { default as _91slug_93cEB1nN3s1oMeta } from "/data/pages/blog/categories/[slug].vue?macro=true";
import { default as index3JdQccMEcZMeta } from "/data/pages/blog/index.vue?macro=true";
import { default as _91slug_93W2c0teDQWlMeta } from "/data/pages/blog/tags/[slug].vue?macro=true";
import { default as indexVag119Ai9QMeta } from "/data/pages/checkout/index.vue?macro=true";
import { default as thank_45youjeL9gqfYoWMeta } from "/data/pages/checkout/thank-you.vue?macro=true";
import { default as _91slug_93eL5cIth4EPMeta } from "/data/pages/clinics/[slug].vue?macro=true";
import { default as index5eSz7sUw3TMeta } from "/data/pages/clinics/index.vue?macro=true";
import { default as contact_45usVb7LZb4YQxMeta } from "/data/pages/contact-us.vue?macro=true";
import { default as indexojFm3fXhJiMeta } from "/data/pages/direct-payment/[order_token]/index.vue?macro=true";
import { default as _91slug_93wLojpWP9YDMeta } from "/data/pages/faqs/[slug].vue?macro=true";
import { default as indexLfZweG7Ef2Meta } from "/data/pages/faqs/index.vue?macro=true";
import { default as index0sTCdVluXDMeta } from "/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue?macro=true";
import { default as _91condition_93IYA8PUM8TrMeta } from "/data/pages/help-and-advice/[category]/[slug]/[condition].vue?macro=true";
import { default as indexZeiT6zQX5VMeta } from "/data/pages/help-and-advice/[category]/[slug]/index.vue?macro=true";
import { default as index9wTWHSpoWsMeta } from "/data/pages/help-and-advice/[category]/index.vue?macro=true";
import { default as indexjAaK7ryr1uMeta } from "/data/pages/help-and-advice/index.vue?macro=true";
import { default as indexwJM0pWSZGwMeta } from "/data/pages/index.vue?macro=true";
import { default as _91slug_93VAORsljsIBMeta } from "/data/pages/legal-and-privacy/[slug].vue?macro=true";
import { default as indexSWPrnbyjTJMeta } from "/data/pages/legal-and-privacy/index.vue?macro=true";
import { default as policy_45detailtnulJGjISmMeta } from "/data/pages/policy-detail.vue?macro=true";
import { default as index2QwGis8PcNMeta } from "/data/pages/reviews/index.vue?macro=true";
import { default as indexjNcQzb30nMMeta } from "/data/pages/tests/[id]/[slug]/index.vue?macro=true";
import { default as index1s4p7SZOGtMeta } from "/data/pages/tests/[id]/index.vue?macro=true";
import { default as _91id_93ipsotPlbY8Meta } from "/data/pages/tests/health-and-wellness/[id].vue?macro=true";
import { default as indexi5j1BAjR1wMeta } from "/data/pages/tests/index.vue?macro=true";
export default [
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en",
    path: awardsGZE03KY3bbMeta?.path ?? "/en/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-bo",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-bo/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-bo",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-bo/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-bz",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-bz/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-bz",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-bz/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-cl",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-cl/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-cl",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-cl/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-co",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-co/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-co",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-co/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-cr",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-cr/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-cr",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-cr/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-ec",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-ec/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-ec",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-ec/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-es",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-es/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-es",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-es/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-ge",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-ge/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-gb",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-gb/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-hk",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-hk/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-hu",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-hu/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___hu-hu",
    path: awardsGZE03KY3bbMeta?.path ?? "/hu-hu/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-au",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-au/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-mx",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-mx/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-mx",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-mx/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-ni",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-ni/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-ni",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-ni/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-pa",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-pa/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-pa",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-pa/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-pe",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-pe/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-pe",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-pe/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-pl",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-pl/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___pl-pl",
    path: awardsGZE03KY3bbMeta?.path ?? "/pl-pl/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-pr",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-pr/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-pr",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-pr/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-pt",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-pt/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___pt-pt",
    path: awardsGZE03KY3bbMeta?.path ?? "/pt-pt/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___en-py",
    path: awardsGZE03KY3bbMeta?.path ?? "/en-py/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: awardsGZE03KY3bbMeta?.name ?? "about-us-awards___es-py",
    path: awardsGZE03KY3bbMeta?.path ?? "/es-py/about-us/awards",
    meta: awardsGZE03KY3bbMeta || {},
    alias: awardsGZE03KY3bbMeta?.alias || [],
    redirect: awardsGZE03KY3bbMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/awards.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-bo",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-bo/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-bo",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-bo/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-bz",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-bz/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-bz",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-bz/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-cl",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-cl/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-cl",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-cl/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-co",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-co/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-co",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-co/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-cr",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-cr/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-cr",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-cr/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-ec",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-ec/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-ec",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-ec/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-es",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-es/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-es",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-es/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-ge",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-ge/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-gb",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-gb/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-hk",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-hk/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-hu",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-hu/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___hu-hu",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/hu-hu/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-au",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-au/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-mx",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-mx/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-mx",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-mx/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-ni",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-ni/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-ni",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-ni/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-pa",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-pa/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-pa",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-pa/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-pe",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-pe/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-pe",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-pe/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-pl",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-pl/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___pl-pl",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/pl-pl/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-pr",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-pr/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-pr",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-pr/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-pt",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-pt/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___pt-pt",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/pt-pt/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___en-py",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/en-py/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: in_45the_45media3XkjronnXvMeta?.name ?? "about-us-in-the-media___es-py",
    path: in_45the_45media3XkjronnXvMeta?.path ?? "/es-py/about-us/in-the-media",
    meta: in_45the_45media3XkjronnXvMeta || {},
    alias: in_45the_45media3XkjronnXvMeta?.alias || [],
    redirect: in_45the_45media3XkjronnXvMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/in-the-media.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en",
    path: indexw4ONCROkyTMeta?.path ?? "/en/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-bo",
    path: indexw4ONCROkyTMeta?.path ?? "/en-bo/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-bo",
    path: indexw4ONCROkyTMeta?.path ?? "/es-bo/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-bz",
    path: indexw4ONCROkyTMeta?.path ?? "/en-bz/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-bz",
    path: indexw4ONCROkyTMeta?.path ?? "/es-bz/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-cl",
    path: indexw4ONCROkyTMeta?.path ?? "/en-cl/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-cl",
    path: indexw4ONCROkyTMeta?.path ?? "/es-cl/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-co",
    path: indexw4ONCROkyTMeta?.path ?? "/en-co/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-co",
    path: indexw4ONCROkyTMeta?.path ?? "/es-co/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-cr",
    path: indexw4ONCROkyTMeta?.path ?? "/en-cr/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-cr",
    path: indexw4ONCROkyTMeta?.path ?? "/es-cr/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-ec",
    path: indexw4ONCROkyTMeta?.path ?? "/en-ec/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-ec",
    path: indexw4ONCROkyTMeta?.path ?? "/es-ec/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-es",
    path: indexw4ONCROkyTMeta?.path ?? "/en-es/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-es",
    path: indexw4ONCROkyTMeta?.path ?? "/es-es/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-ge",
    path: indexw4ONCROkyTMeta?.path ?? "/en-ge/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-gb",
    path: indexw4ONCROkyTMeta?.path ?? "/en-gb/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-hk",
    path: indexw4ONCROkyTMeta?.path ?? "/en-hk/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-hu",
    path: indexw4ONCROkyTMeta?.path ?? "/en-hu/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___hu-hu",
    path: indexw4ONCROkyTMeta?.path ?? "/hu-hu/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-au",
    path: indexw4ONCROkyTMeta?.path ?? "/en-au/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-mx",
    path: indexw4ONCROkyTMeta?.path ?? "/en-mx/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-mx",
    path: indexw4ONCROkyTMeta?.path ?? "/es-mx/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-ni",
    path: indexw4ONCROkyTMeta?.path ?? "/en-ni/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-ni",
    path: indexw4ONCROkyTMeta?.path ?? "/es-ni/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-pa",
    path: indexw4ONCROkyTMeta?.path ?? "/en-pa/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-pa",
    path: indexw4ONCROkyTMeta?.path ?? "/es-pa/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-pe",
    path: indexw4ONCROkyTMeta?.path ?? "/en-pe/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-pe",
    path: indexw4ONCROkyTMeta?.path ?? "/es-pe/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-pl",
    path: indexw4ONCROkyTMeta?.path ?? "/en-pl/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___pl-pl",
    path: indexw4ONCROkyTMeta?.path ?? "/pl-pl/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-pr",
    path: indexw4ONCROkyTMeta?.path ?? "/en-pr/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-pr",
    path: indexw4ONCROkyTMeta?.path ?? "/es-pr/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-pt",
    path: indexw4ONCROkyTMeta?.path ?? "/en-pt/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___pt-pt",
    path: indexw4ONCROkyTMeta?.path ?? "/pt-pt/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___en-py",
    path: indexw4ONCROkyTMeta?.path ?? "/en-py/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexw4ONCROkyTMeta?.name ?? "about-us___es-py",
    path: indexw4ONCROkyTMeta?.path ?? "/es-py/about-us",
    meta: indexw4ONCROkyTMeta || {},
    alias: indexw4ONCROkyTMeta?.alias || [],
    redirect: indexw4ONCROkyTMeta?.redirect || undefined,
    component: () => import("/data/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en",
    path: indexfo5VOb1sbeMeta?.path ?? "/en/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-bo",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-bo/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-bo",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-bo/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-bz",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-bz/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-bz",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-bz/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-cl",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-cl/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-cl",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-cl/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-co",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-co/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-co",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-co/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-cr",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-cr/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-cr",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-cr/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-ec",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-ec/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-ec",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-ec/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-es",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-es/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-es",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-es/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-ge",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-ge/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-gb",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-gb/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-hk",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-hk/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-hu",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-hu/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___hu-hu",
    path: indexfo5VOb1sbeMeta?.path ?? "/hu-hu/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-au",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-au/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-mx",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-mx/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-mx",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-mx/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-ni",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-ni/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-ni",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-ni/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-pa",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-pa/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-pa",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-pa/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-pe",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-pe/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-pe",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-pe/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-pl",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-pl/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___pl-pl",
    path: indexfo5VOb1sbeMeta?.path ?? "/pl-pl/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-pr",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-pr/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-pr",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-pr/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-pt",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-pt/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___pt-pt",
    path: indexfo5VOb1sbeMeta?.path ?? "/pt-pt/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___en-py",
    path: indexfo5VOb1sbeMeta?.path ?? "/en-py/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: indexfo5VOb1sbeMeta?.name ?? "account-page___es-py",
    path: indexfo5VOb1sbeMeta?.path ?? "/es-py/account/:page()",
    meta: indexfo5VOb1sbeMeta || {},
    alias: indexfo5VOb1sbeMeta?.alias || [],
    redirect: indexfo5VOb1sbeMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-bo",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-bo/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-bo",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-bo/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-bz",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-bz/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-bz",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-bz/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-cl",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-cl/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-cl",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-cl/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-co",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-co/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-co",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-co/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-cr",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-cr/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-cr",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-cr/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-ec",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-ec/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-ec",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-ec/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-es",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-es/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-es",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-es/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-ge",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-ge/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-gb",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-gb/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-hk",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-hk/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-hu",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-hu/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___hu-hu",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/hu-hu/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-au",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-au/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-mx",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-mx/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-mx",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-mx/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-ni",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-ni/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-ni",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-ni/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-pa",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-pa/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-pa",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-pa/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-pe",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-pe/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-pe",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-pe/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-pl",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-pl/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___pl-pl",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/pl-pl/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-pr",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-pr/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-pr",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-pr/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-pt",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-pt/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___pt-pt",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/pt-pt/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___en-py",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/en-py/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: account_45cardv0ubeQnsOBMeta?.name ?? "account-page-protected-views-account-settings-components-account-card___es-py",
    path: account_45cardv0ubeQnsOBMeta?.path ?? "/es-py/account/:page()/protected/views/account-settings/components/account-card",
    meta: account_45cardv0ubeQnsOBMeta || {},
    alias: account_45cardv0ubeQnsOBMeta?.alias || [],
    redirect: account_45cardv0ubeQnsOBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/components/account-card.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en",
    path: indexlWWDf54wTDMeta?.path ?? "/en/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-bo",
    path: indexlWWDf54wTDMeta?.path ?? "/en-bo/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-bo",
    path: indexlWWDf54wTDMeta?.path ?? "/es-bo/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-bz",
    path: indexlWWDf54wTDMeta?.path ?? "/en-bz/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-bz",
    path: indexlWWDf54wTDMeta?.path ?? "/es-bz/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-cl",
    path: indexlWWDf54wTDMeta?.path ?? "/en-cl/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-cl",
    path: indexlWWDf54wTDMeta?.path ?? "/es-cl/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-co",
    path: indexlWWDf54wTDMeta?.path ?? "/en-co/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-co",
    path: indexlWWDf54wTDMeta?.path ?? "/es-co/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-cr",
    path: indexlWWDf54wTDMeta?.path ?? "/en-cr/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-cr",
    path: indexlWWDf54wTDMeta?.path ?? "/es-cr/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-ec",
    path: indexlWWDf54wTDMeta?.path ?? "/en-ec/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-ec",
    path: indexlWWDf54wTDMeta?.path ?? "/es-ec/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-es",
    path: indexlWWDf54wTDMeta?.path ?? "/en-es/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-es",
    path: indexlWWDf54wTDMeta?.path ?? "/es-es/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-ge",
    path: indexlWWDf54wTDMeta?.path ?? "/en-ge/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-gb",
    path: indexlWWDf54wTDMeta?.path ?? "/en-gb/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-hk",
    path: indexlWWDf54wTDMeta?.path ?? "/en-hk/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-hu",
    path: indexlWWDf54wTDMeta?.path ?? "/en-hu/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___hu-hu",
    path: indexlWWDf54wTDMeta?.path ?? "/hu-hu/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-au",
    path: indexlWWDf54wTDMeta?.path ?? "/en-au/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-mx",
    path: indexlWWDf54wTDMeta?.path ?? "/en-mx/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-mx",
    path: indexlWWDf54wTDMeta?.path ?? "/es-mx/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-ni",
    path: indexlWWDf54wTDMeta?.path ?? "/en-ni/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-ni",
    path: indexlWWDf54wTDMeta?.path ?? "/es-ni/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-pa",
    path: indexlWWDf54wTDMeta?.path ?? "/en-pa/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-pa",
    path: indexlWWDf54wTDMeta?.path ?? "/es-pa/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-pe",
    path: indexlWWDf54wTDMeta?.path ?? "/en-pe/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-pe",
    path: indexlWWDf54wTDMeta?.path ?? "/es-pe/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-pl",
    path: indexlWWDf54wTDMeta?.path ?? "/en-pl/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___pl-pl",
    path: indexlWWDf54wTDMeta?.path ?? "/pl-pl/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-pr",
    path: indexlWWDf54wTDMeta?.path ?? "/en-pr/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-pr",
    path: indexlWWDf54wTDMeta?.path ?? "/es-pr/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-pt",
    path: indexlWWDf54wTDMeta?.path ?? "/en-pt/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___pt-pt",
    path: indexlWWDf54wTDMeta?.path ?? "/pt-pt/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___en-py",
    path: indexlWWDf54wTDMeta?.path ?? "/en-py/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexlWWDf54wTDMeta?.name ?? "account-page-protected-views-account-settings___es-py",
    path: indexlWWDf54wTDMeta?.path ?? "/es-py/account/:page()/protected/views/account-settings",
    meta: indexlWWDf54wTDMeta || {},
    alias: indexlWWDf54wTDMeta?.alias || [],
    redirect: indexlWWDf54wTDMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-bo",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-bo/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-bo",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-bo/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-bz",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-bz/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-bz",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-bz/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-cl",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-cl/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-cl",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-cl/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-co",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-co/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-co",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-co/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-cr",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-cr/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-cr",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-cr/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-ec",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-ec/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-ec",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-ec/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-es",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-es/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-es",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-es/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-ge",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-ge/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-gb",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-gb/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-hk",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-hk/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-hu",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-hu/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___hu-hu",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/hu-hu/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-au",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-au/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-mx",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-mx/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-mx",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-mx/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-ni",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-ni/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-ni",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-ni/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-pa",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-pa/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-pa",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-pa/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-pe",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-pe/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-pe",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-pe/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-pl",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-pl/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___pl-pl",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/pl-pl/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-pr",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-pr/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-pr",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-pr/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-pt",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-pt/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___pt-pt",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/pt-pt/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___en-py",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/en-py/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountFWElTUQQYpMeta?.name ?? "account-page-protected-views-account-settings-sections-delete-account___es-py",
    path: delete_45accountFWElTUQQYpMeta?.path ?? "/es-py/account/:page()/protected/views/account-settings/sections/delete-account",
    meta: delete_45accountFWElTUQQYpMeta || {},
    alias: delete_45accountFWElTUQQYpMeta?.alias || [],
    redirect: delete_45accountFWElTUQQYpMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delete-account.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-bo",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-bo/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-bo",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-bo/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-bz",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-bz/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-bz",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-bz/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-cl",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-cl/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-cl",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-cl/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-co",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-co/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-co",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-co/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-cr",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-cr/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-cr",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-cr/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-ec",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-ec/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-ec",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-ec/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-es",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-es/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-es",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-es/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-ge",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-ge/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-gb",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-gb/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-hk",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-hk/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-hu",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-hu/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___hu-hu",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/hu-hu/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-au",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-au/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-mx",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-mx/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-mx",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-mx/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-ni",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-ni/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-ni",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-ni/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-pa",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-pa/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-pa",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-pa/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-pe",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-pe/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-pe",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-pe/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-pl",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-pl/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___pl-pl",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/pl-pl/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-pr",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-pr/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-pr",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-pr/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-pt",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-pt/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___pt-pt",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/pt-pt/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___en-py",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/en-py/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: delivery_45addressp3W0d1NjonMeta?.name ?? "account-page-protected-views-account-settings-sections-delivery-address___es-py",
    path: delivery_45addressp3W0d1NjonMeta?.path ?? "/es-py/account/:page()/protected/views/account-settings/sections/delivery-address",
    meta: delivery_45addressp3W0d1NjonMeta || {},
    alias: delivery_45addressp3W0d1NjonMeta?.alias || [],
    redirect: delivery_45addressp3W0d1NjonMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/delivery-address.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en",
    path: emaildPRsz9lcXNMeta?.path ?? "/en/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-bo",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-bo/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-bo",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-bo/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-bz",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-bz/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-bz",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-bz/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-cl",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-cl/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-cl",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-cl/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-co",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-co/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-co",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-co/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-cr",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-cr/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-cr",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-cr/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-ec",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-ec/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-ec",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-ec/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-es",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-es/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-es",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-es/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-ge",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-ge/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-gb",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-gb/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-hk",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-hk/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-hu",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-hu/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___hu-hu",
    path: emaildPRsz9lcXNMeta?.path ?? "/hu-hu/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-au",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-au/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-mx",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-mx/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-mx",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-mx/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-ni",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-ni/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-ni",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-ni/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-pa",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-pa/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-pa",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-pa/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-pe",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-pe/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-pe",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-pe/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-pl",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-pl/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___pl-pl",
    path: emaildPRsz9lcXNMeta?.path ?? "/pl-pl/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-pr",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-pr/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-pr",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-pr/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-pt",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-pt/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___pt-pt",
    path: emaildPRsz9lcXNMeta?.path ?? "/pt-pt/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___en-py",
    path: emaildPRsz9lcXNMeta?.path ?? "/en-py/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: emaildPRsz9lcXNMeta?.name ?? "account-page-protected-views-account-settings-sections-email___es-py",
    path: emaildPRsz9lcXNMeta?.path ?? "/es-py/account/:page()/protected/views/account-settings/sections/email",
    meta: emaildPRsz9lcXNMeta || {},
    alias: emaildPRsz9lcXNMeta?.alias || [],
    redirect: emaildPRsz9lcXNMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/email.vue").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en",
    path: indexopwKIz2VbqMeta?.path ?? "/en/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-bo",
    path: indexopwKIz2VbqMeta?.path ?? "/en-bo/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-bo",
    path: indexopwKIz2VbqMeta?.path ?? "/es-bo/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-bz",
    path: indexopwKIz2VbqMeta?.path ?? "/en-bz/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-bz",
    path: indexopwKIz2VbqMeta?.path ?? "/es-bz/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-cl",
    path: indexopwKIz2VbqMeta?.path ?? "/en-cl/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-cl",
    path: indexopwKIz2VbqMeta?.path ?? "/es-cl/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-co",
    path: indexopwKIz2VbqMeta?.path ?? "/en-co/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-co",
    path: indexopwKIz2VbqMeta?.path ?? "/es-co/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-cr",
    path: indexopwKIz2VbqMeta?.path ?? "/en-cr/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-cr",
    path: indexopwKIz2VbqMeta?.path ?? "/es-cr/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-ec",
    path: indexopwKIz2VbqMeta?.path ?? "/en-ec/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-ec",
    path: indexopwKIz2VbqMeta?.path ?? "/es-ec/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-es",
    path: indexopwKIz2VbqMeta?.path ?? "/en-es/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-es",
    path: indexopwKIz2VbqMeta?.path ?? "/es-es/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-ge",
    path: indexopwKIz2VbqMeta?.path ?? "/en-ge/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-gb",
    path: indexopwKIz2VbqMeta?.path ?? "/en-gb/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-hk",
    path: indexopwKIz2VbqMeta?.path ?? "/en-hk/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-hu",
    path: indexopwKIz2VbqMeta?.path ?? "/en-hu/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___hu-hu",
    path: indexopwKIz2VbqMeta?.path ?? "/hu-hu/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-au",
    path: indexopwKIz2VbqMeta?.path ?? "/en-au/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-mx",
    path: indexopwKIz2VbqMeta?.path ?? "/en-mx/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-mx",
    path: indexopwKIz2VbqMeta?.path ?? "/es-mx/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-ni",
    path: indexopwKIz2VbqMeta?.path ?? "/en-ni/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-ni",
    path: indexopwKIz2VbqMeta?.path ?? "/es-ni/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-pa",
    path: indexopwKIz2VbqMeta?.path ?? "/en-pa/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-pa",
    path: indexopwKIz2VbqMeta?.path ?? "/es-pa/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-pe",
    path: indexopwKIz2VbqMeta?.path ?? "/en-pe/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-pe",
    path: indexopwKIz2VbqMeta?.path ?? "/es-pe/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-pl",
    path: indexopwKIz2VbqMeta?.path ?? "/en-pl/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___pl-pl",
    path: indexopwKIz2VbqMeta?.path ?? "/pl-pl/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-pr",
    path: indexopwKIz2VbqMeta?.path ?? "/en-pr/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-pr",
    path: indexopwKIz2VbqMeta?.path ?? "/es-pr/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-pt",
    path: indexopwKIz2VbqMeta?.path ?? "/en-pt/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___pt-pt",
    path: indexopwKIz2VbqMeta?.path ?? "/pt-pt/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___en-py",
    path: indexopwKIz2VbqMeta?.path ?? "/en-py/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: indexopwKIz2VbqMeta?.name ?? "account-page-protected-views-account-settings-sections___es-py",
    path: indexopwKIz2VbqMeta?.path ?? "/es-py/account/:page()/protected/views/account-settings/sections",
    meta: indexopwKIz2VbqMeta || {},
    alias: indexopwKIz2VbqMeta?.alias || [],
    redirect: indexopwKIz2VbqMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/index.ts").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en",
    path: password7pYgeVy1fMMeta?.path ?? "/en/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-bo",
    path: password7pYgeVy1fMMeta?.path ?? "/en-bo/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-bo",
    path: password7pYgeVy1fMMeta?.path ?? "/es-bo/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-bz",
    path: password7pYgeVy1fMMeta?.path ?? "/en-bz/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-bz",
    path: password7pYgeVy1fMMeta?.path ?? "/es-bz/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-cl",
    path: password7pYgeVy1fMMeta?.path ?? "/en-cl/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-cl",
    path: password7pYgeVy1fMMeta?.path ?? "/es-cl/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-co",
    path: password7pYgeVy1fMMeta?.path ?? "/en-co/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-co",
    path: password7pYgeVy1fMMeta?.path ?? "/es-co/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-cr",
    path: password7pYgeVy1fMMeta?.path ?? "/en-cr/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-cr",
    path: password7pYgeVy1fMMeta?.path ?? "/es-cr/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-ec",
    path: password7pYgeVy1fMMeta?.path ?? "/en-ec/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-ec",
    path: password7pYgeVy1fMMeta?.path ?? "/es-ec/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-es",
    path: password7pYgeVy1fMMeta?.path ?? "/en-es/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-es",
    path: password7pYgeVy1fMMeta?.path ?? "/es-es/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-ge",
    path: password7pYgeVy1fMMeta?.path ?? "/en-ge/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-gb",
    path: password7pYgeVy1fMMeta?.path ?? "/en-gb/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-hk",
    path: password7pYgeVy1fMMeta?.path ?? "/en-hk/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-hu",
    path: password7pYgeVy1fMMeta?.path ?? "/en-hu/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___hu-hu",
    path: password7pYgeVy1fMMeta?.path ?? "/hu-hu/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-au",
    path: password7pYgeVy1fMMeta?.path ?? "/en-au/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-mx",
    path: password7pYgeVy1fMMeta?.path ?? "/en-mx/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-mx",
    path: password7pYgeVy1fMMeta?.path ?? "/es-mx/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-ni",
    path: password7pYgeVy1fMMeta?.path ?? "/en-ni/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-ni",
    path: password7pYgeVy1fMMeta?.path ?? "/es-ni/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-pa",
    path: password7pYgeVy1fMMeta?.path ?? "/en-pa/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-pa",
    path: password7pYgeVy1fMMeta?.path ?? "/es-pa/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-pe",
    path: password7pYgeVy1fMMeta?.path ?? "/en-pe/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-pe",
    path: password7pYgeVy1fMMeta?.path ?? "/es-pe/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-pl",
    path: password7pYgeVy1fMMeta?.path ?? "/en-pl/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___pl-pl",
    path: password7pYgeVy1fMMeta?.path ?? "/pl-pl/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-pr",
    path: password7pYgeVy1fMMeta?.path ?? "/en-pr/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-pr",
    path: password7pYgeVy1fMMeta?.path ?? "/es-pr/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-pt",
    path: password7pYgeVy1fMMeta?.path ?? "/en-pt/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___pt-pt",
    path: password7pYgeVy1fMMeta?.path ?? "/pt-pt/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___en-py",
    path: password7pYgeVy1fMMeta?.path ?? "/en-py/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: password7pYgeVy1fMMeta?.name ?? "account-page-protected-views-account-settings-sections-password___es-py",
    path: password7pYgeVy1fMMeta?.path ?? "/es-py/account/:page()/protected/views/account-settings/sections/password",
    meta: password7pYgeVy1fMMeta || {},
    alias: password7pYgeVy1fMMeta?.alias || [],
    redirect: password7pYgeVy1fMMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/password.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-bo",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-bo/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-bo",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-bo/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-bz",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-bz/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-bz",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-bz/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-cl",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-cl/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-cl",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-cl/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-co",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-co/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-co",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-co/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-cr",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-cr/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-cr",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-cr/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-ec",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-ec/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-ec",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-ec/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-es",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-es/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-es",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-es/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-ge",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-ge/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-gb",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-gb/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-hk",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-hk/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-hu",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-hu/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___hu-hu",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/hu-hu/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-au",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-au/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-mx",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-mx/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-mx",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-mx/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-ni",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-ni/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-ni",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-ni/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-pa",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-pa/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-pa",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-pa/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-pe",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-pe/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-pe",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-pe/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-pl",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-pl/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___pl-pl",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/pl-pl/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-pr",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-pr/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-pr",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-pr/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-pt",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-pt/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___pt-pt",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/pt-pt/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___en-py",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/en-py/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: personal_45detailsqBwHqe4JkdMeta?.name ?? "account-page-protected-views-account-settings-sections-personal-details___es-py",
    path: personal_45detailsqBwHqe4JkdMeta?.path ?? "/es-py/account/:page()/protected/views/account-settings/sections/personal-details",
    meta: personal_45detailsqBwHqe4JkdMeta || {},
    alias: personal_45detailsqBwHqe4JkdMeta?.alias || [],
    redirect: personal_45detailsqBwHqe4JkdMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/personal-details.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-bo",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-bo/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-bo",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-bo/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-bz",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-bz/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-bz",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-bz/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-cl",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-cl/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-cl",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-cl/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-co",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-co/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-co",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-co/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-cr",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-cr/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-cr",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-cr/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-ec",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-ec/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-ec",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-ec/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-es",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-es/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-es",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-es/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-ge",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-ge/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-gb",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-gb/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-hk",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-hk/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-hu",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-hu/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___hu-hu",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/hu-hu/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-au",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-au/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-mx",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-mx/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-mx",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-mx/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-ni",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-ni/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-ni",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-ni/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-pa",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-pa/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-pa",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-pa/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-pe",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-pe/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-pe",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-pe/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-pl",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-pl/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___pl-pl",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/pl-pl/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-pr",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-pr/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-pr",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-pr/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-pt",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-pt/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___pt-pt",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/pt-pt/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___en-py",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/en-py/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: phone_45numberElsv4wwgmBMeta?.name ?? "account-page-protected-views-account-settings-sections-phone-number___es-py",
    path: phone_45numberElsv4wwgmBMeta?.path ?? "/es-py/account/:page()/protected/views/account-settings/sections/phone-number",
    meta: phone_45numberElsv4wwgmBMeta || {},
    alias: phone_45numberElsv4wwgmBMeta?.alias || [],
    redirect: phone_45numberElsv4wwgmBMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/account-settings/sections/phone-number.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-bo",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-bo/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-bo",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-bo/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-bz",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-bz/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-bz",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-bz/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-cl",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-cl/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-cl",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-cl/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-co",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-co/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-co",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-co/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-cr",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-cr/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-cr",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-cr/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-ec",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-ec/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-ec",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-ec/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-es",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-es/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-es",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-es/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-ge",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-ge/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-gb",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-gb/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-hk",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-hk/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-hu",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-hu/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___hu-hu",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/hu-hu/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-au",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-au/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-mx",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-mx/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-mx",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-mx/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-ni",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-ni/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-ni",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-ni/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-pa",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-pa/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-pa",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-pa/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-pe",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-pe/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-pe",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-pe/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-pl",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-pl/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___pl-pl",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/pl-pl/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-pr",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-pr/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-pr",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-pr/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-pt",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-pt/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___pt-pt",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/pt-pt/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___en-py",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/en-py/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutdsr9bcVHE4Meta?.name ?? "account-page-protected-views-order-history-components-device-signout___es-py",
    path: device_45signoutdsr9bcVHE4Meta?.path ?? "/es-py/account/:page()/protected/views/order-history/components/device-signout",
    meta: device_45signoutdsr9bcVHE4Meta || {},
    alias: device_45signoutdsr9bcVHE4Meta?.alias || [],
    redirect: device_45signoutdsr9bcVHE4Meta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-bo",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-bo/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-bo",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-bo/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-bz",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-bz/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-bz",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-bz/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-cl",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-cl/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-cl",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-cl/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-co",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-co/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-co",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-co/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-cr",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-cr/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-cr",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-cr/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-ec",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-ec/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-ec",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-ec/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-es",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-es/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-es",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-es/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-ge",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-ge/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-gb",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-gb/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-hk",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-hk/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-hu",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-hu/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___hu-hu",
    path: indexRd1ZMxNn5bMeta?.path ?? "/hu-hu/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-au",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-au/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-mx",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-mx/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-mx",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-mx/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-ni",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-ni/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-ni",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-ni/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-pa",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-pa/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-pa",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-pa/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-pe",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-pe/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-pe",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-pe/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-pl",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-pl/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___pl-pl",
    path: indexRd1ZMxNn5bMeta?.path ?? "/pl-pl/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-pr",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-pr/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-pr",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-pr/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-pt",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-pt/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___pt-pt",
    path: indexRd1ZMxNn5bMeta?.path ?? "/pt-pt/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___en-py",
    path: indexRd1ZMxNn5bMeta?.path ?? "/en-py/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexRd1ZMxNn5bMeta?.name ?? "account-page-protected-views-order-history___es-py",
    path: indexRd1ZMxNn5bMeta?.path ?? "/es-py/account/:page()/protected/views/order-history",
    meta: indexRd1ZMxNn5bMeta || {},
    alias: indexRd1ZMxNn5bMeta?.alias || [],
    redirect: indexRd1ZMxNn5bMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-bo",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-bo/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-bo",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-bo/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-bz",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-bz/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-bz",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-bz/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-cl",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-cl/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-cl",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-cl/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-co",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-co/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-co",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-co/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-cr",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-cr/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-cr",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-cr/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-ec",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-ec/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-ec",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-ec/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-es",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-es/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-es",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-es/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-ge",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-ge/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-gb",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-gb/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-hk",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-hk/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-hu",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-hu/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___hu-hu",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/hu-hu/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-au",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-au/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-mx",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-mx/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-mx",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-mx/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-ni",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-ni/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-ni",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-ni/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-pa",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-pa/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-pa",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-pa/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-pe",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-pe/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-pe",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-pe/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-pl",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-pl/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___pl-pl",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/pl-pl/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-pr",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-pr/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-pr",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-pr/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-pt",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-pt/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___pt-pt",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/pt-pt/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___en-py",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/en-py/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: device_45signoutEa8ed5ZbwHMeta?.name ?? "account-page-protected-views-security-components-device-signout___es-py",
    path: device_45signoutEa8ed5ZbwHMeta?.path ?? "/es-py/account/:page()/protected/views/security/components/device-signout",
    meta: device_45signoutEa8ed5ZbwHMeta || {},
    alias: device_45signoutEa8ed5ZbwHMeta?.alias || [],
    redirect: device_45signoutEa8ed5ZbwHMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/components/device-signout.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en",
    path: indexz1wzNUfIjJMeta?.path ?? "/en/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-bo",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-bo/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-bo",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-bo/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-bz",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-bz/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-bz",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-bz/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-cl",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-cl/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-cl",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-cl/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-co",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-co/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-co",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-co/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-cr",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-cr/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-cr",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-cr/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-ec",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-ec/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-ec",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-ec/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-es",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-es/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-es",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-es/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-ge",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-ge/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-gb",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-gb/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-hk",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-hk/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-hu",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-hu/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___hu-hu",
    path: indexz1wzNUfIjJMeta?.path ?? "/hu-hu/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-au",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-au/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-mx",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-mx/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-mx",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-mx/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-ni",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-ni/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-ni",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-ni/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-pa",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-pa/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-pa",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-pa/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-pe",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-pe/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-pe",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-pe/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-pl",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-pl/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___pl-pl",
    path: indexz1wzNUfIjJMeta?.path ?? "/pl-pl/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-pr",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-pr/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-pr",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-pr/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-pt",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-pt/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___pt-pt",
    path: indexz1wzNUfIjJMeta?.path ?? "/pt-pt/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___en-py",
    path: indexz1wzNUfIjJMeta?.path ?? "/en-py/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexz1wzNUfIjJMeta?.name ?? "account-page-protected-views-security___es-py",
    path: indexz1wzNUfIjJMeta?.path ?? "/es-py/account/:page()/protected/views/security",
    meta: indexz1wzNUfIjJMeta || {},
    alias: indexz1wzNUfIjJMeta?.alias || [],
    redirect: indexz1wzNUfIjJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/protected/views/security/index.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-bo",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-bo/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-bo",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-bo/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-bz",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-bz/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-bz",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-bz/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-cl",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-cl/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-cl",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-cl/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-co",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-co/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-co",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-co/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-cr",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-cr/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-cr",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-cr/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-ec",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-ec/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-ec",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-ec/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-es",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-es/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-es",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-es/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-ge",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-ge/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-gb",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-gb/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-hk",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-hk/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-hu",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-hu/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___hu-hu",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/hu-hu/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-au",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-au/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-mx",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-mx/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-mx",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-mx/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-ni",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-ni/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-ni",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-ni/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-pa",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-pa/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-pa",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-pa/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-pe",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-pe/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-pe",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-pe/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-pl",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-pl/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___pl-pl",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/pl-pl/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-pr",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-pr/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-pr",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-pr/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-pt",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-pt/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___pt-pt",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/pt-pt/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___en-py",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/en-py/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45containerUm5Lrhj0IwMeta?.name ?? "account-page-public-components-auth-container___es-py",
    path: auth_45containerUm5Lrhj0IwMeta?.path ?? "/es-py/account/:page()/public/components/auth-container",
    meta: auth_45containerUm5Lrhj0IwMeta || {},
    alias: auth_45containerUm5Lrhj0IwMeta?.alias || [],
    redirect: auth_45containerUm5Lrhj0IwMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-container.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-bo",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-bo/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-bo",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-bo/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-bz",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-bz/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-bz",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-bz/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-cl",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-cl/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-cl",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-cl/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-co",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-co/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-co",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-co/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-cr",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-cr/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-cr",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-cr/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-ec",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-ec/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-ec",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-ec/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-es",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-es/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-es",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-es/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-ge",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-ge/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-gb",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-gb/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-hk",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-hk/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-hu",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-hu/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___hu-hu",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/hu-hu/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-au",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-au/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-mx",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-mx/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-mx",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-mx/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-ni",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-ni/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-ni",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-ni/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-pa",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-pa/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-pa",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-pa/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-pe",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-pe/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-pe",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-pe/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-pl",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-pl/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___pl-pl",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/pl-pl/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-pr",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-pr/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-pr",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-pr/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-pt",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-pt/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___pt-pt",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/pt-pt/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___en-py",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/en-py/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: auth_45windowzXElIeYSiiMeta?.name ?? "account-page-public-components-auth-window___es-py",
    path: auth_45windowzXElIeYSiiMeta?.path ?? "/es-py/account/:page()/public/components/auth-window",
    meta: auth_45windowzXElIeYSiiMeta || {},
    alias: auth_45windowzXElIeYSiiMeta?.alias || [],
    redirect: auth_45windowzXElIeYSiiMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/auth-window.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-bo",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-bo/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-bo",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-bo/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-bz",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-bz/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-bz",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-bz/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-cl",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-cl/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-cl",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-cl/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-co",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-co/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-co",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-co/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-cr",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-cr/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-cr",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-cr/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-ec",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-ec/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-ec",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-ec/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-es",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-es/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-es",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-es/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-ge",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-ge/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-gb",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-gb/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-hk",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-hk/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-hu",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-hu/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___hu-hu",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/hu-hu/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-au",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-au/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-mx",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-mx/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-mx",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-mx/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-ni",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-ni/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-ni",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-ni/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-pa",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-pa/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-pa",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-pa/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-pe",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-pe/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-pe",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-pe/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-pl",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-pl/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___pl-pl",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/pl-pl/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-pr",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-pr/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-pr",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-pr/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-pt",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-pt/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___pt-pt",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/pt-pt/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___en-py",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/en-py/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: page_45headerwWpL8Krq7tMeta?.name ?? "account-page-public-components-page-header___es-py",
    path: page_45headerwWpL8Krq7tMeta?.path ?? "/es-py/account/:page()/public/components/page-header",
    meta: page_45headerwWpL8Krq7tMeta || {},
    alias: page_45headerwWpL8Krq7tMeta?.alias || [],
    redirect: page_45headerwWpL8Krq7tMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/components/page-header.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en",
    path: loginkkIGVYMbBJMeta?.path ?? "/en/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-bo",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-bo/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-bo",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-bo/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-bz",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-bz/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-bz",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-bz/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-cl",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-cl/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-cl",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-cl/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-co",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-co/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-co",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-co/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-cr",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-cr/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-cr",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-cr/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-ec",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-ec/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-ec",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-ec/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-es",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-es/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-es",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-es/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-ge",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-ge/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-gb",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-gb/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-hk",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-hk/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-hu",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-hu/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___hu-hu",
    path: loginkkIGVYMbBJMeta?.path ?? "/hu-hu/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-au",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-au/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-mx",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-mx/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-mx",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-mx/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-ni",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-ni/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-ni",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-ni/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-pa",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-pa/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-pa",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-pa/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-pe",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-pe/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-pe",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-pe/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-pl",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-pl/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___pl-pl",
    path: loginkkIGVYMbBJMeta?.path ?? "/pl-pl/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-pr",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-pr/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-pr",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-pr/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-pt",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-pt/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___pt-pt",
    path: loginkkIGVYMbBJMeta?.path ?? "/pt-pt/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___en-py",
    path: loginkkIGVYMbBJMeta?.path ?? "/en-py/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: loginkkIGVYMbBJMeta?.name ?? "account-page-public-views-login___es-py",
    path: loginkkIGVYMbBJMeta?.path ?? "/es-py/account/:page()/public/views/login",
    meta: loginkkIGVYMbBJMeta || {},
    alias: loginkkIGVYMbBJMeta?.alias || [],
    redirect: loginkkIGVYMbBJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/login.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-bo",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-bo/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-bo",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-bo/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-bz",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-bz/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-bz",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-bz/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-cl",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-cl/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-cl",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-cl/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-co",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-co/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-co",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-co/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-cr",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-cr/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-cr",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-cr/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-ec",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-ec/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-ec",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-ec/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-es",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-es/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-es",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-es/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-ge",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-ge/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-gb",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-gb/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-hk",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-hk/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-hu",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-hu/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___hu-hu",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/hu-hu/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-au",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-au/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-mx",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-mx/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-mx",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-mx/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-ni",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-ni/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-ni",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-ni/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-pa",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-pa/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-pa",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-pa/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-pe",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-pe/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-pe",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-pe/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-pl",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-pl/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___pl-pl",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/pl-pl/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-pr",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-pr/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-pr",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-pr/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-pt",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-pt/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___pt-pt",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/pt-pt/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___en-py",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/en-py/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: password_45resetLMIFhG83YJMeta?.name ?? "account-page-public-views-password-reset___es-py",
    path: password_45resetLMIFhG83YJMeta?.path ?? "/es-py/account/:page()/public/views/password-reset",
    meta: password_45resetLMIFhG83YJMeta || {},
    alias: password_45resetLMIFhG83YJMeta?.alias || [],
    redirect: password_45resetLMIFhG83YJMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/password-reset.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en",
    path: signupU5jtSSJqIRMeta?.path ?? "/en/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-bo",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-bo/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-bo",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-bo/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-bz",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-bz/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-bz",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-bz/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-cl",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-cl/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-cl",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-cl/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-co",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-co/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-co",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-co/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-cr",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-cr/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-cr",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-cr/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-ec",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-ec/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-ec",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-ec/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-es",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-es/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-es",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-es/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-ge",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-ge/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-gb",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-gb/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-hk",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-hk/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-hu",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-hu/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___hu-hu",
    path: signupU5jtSSJqIRMeta?.path ?? "/hu-hu/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-au",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-au/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-mx",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-mx/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-mx",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-mx/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-ni",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-ni/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-ni",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-ni/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-pa",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-pa/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-pa",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-pa/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-pe",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-pe/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-pe",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-pe/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-pl",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-pl/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___pl-pl",
    path: signupU5jtSSJqIRMeta?.path ?? "/pl-pl/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-pr",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-pr/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-pr",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-pr/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-pt",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-pt/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___pt-pt",
    path: signupU5jtSSJqIRMeta?.path ?? "/pt-pt/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___en-py",
    path: signupU5jtSSJqIRMeta?.path ?? "/en-py/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: signupU5jtSSJqIRMeta?.name ?? "account-page-public-views-signup___es-py",
    path: signupU5jtSSJqIRMeta?.path ?? "/es-py/account/:page()/public/views/signup",
    meta: signupU5jtSSJqIRMeta || {},
    alias: signupU5jtSSJqIRMeta?.alias || [],
    redirect: signupU5jtSSJqIRMeta?.redirect || undefined,
    component: () => import("/data/pages/account/[page]/public/views/signup.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-bo",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-bo/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-bo",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-bo/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-bz",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-bz/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-bz",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-bz/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-cl",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-cl/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-cl",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-cl/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-co",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-co/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-co",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-co/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-cr",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-cr/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-cr",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-cr/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-ec",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-ec/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-ec",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-ec/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-es",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-es/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-es",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-es/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-ge",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-ge/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-gb",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-gb/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-hk",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-hk/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-hu",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-hu/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___hu-hu",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/hu-hu/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-au",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-au/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-mx",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-mx/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-mx",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-mx/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-ni",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-ni/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-ni",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-ni/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-pa",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-pa/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-pa",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-pa/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-pe",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-pe/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-pe",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-pe/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-pl",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-pl/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___pl-pl",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/pl-pl/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-pr",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-pr/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-pr",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-pr/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-pt",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-pt/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___pt-pt",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/pt-pt/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___en-py",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/en-py/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: dashboard_45cardy8jkOjeVcjMeta?.name ?? "account-components-dashboard-card___es-py",
    path: dashboard_45cardy8jkOjeVcjMeta?.path ?? "/es-py/account/components/dashboard-card",
    meta: dashboard_45cardy8jkOjeVcjMeta || {},
    alias: dashboard_45cardy8jkOjeVcjMeta?.alias || [],
    redirect: dashboard_45cardy8jkOjeVcjMeta?.redirect || undefined,
    component: () => import("/data/pages/account/components/dashboard-card.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-bo",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-bo/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-bo",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-bo/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-bz",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-bz/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-bz",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-bz/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-cl",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-cl/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-cl",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-cl/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-co",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-co/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-co",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-co/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-cr",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-cr/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-cr",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-cr/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-ec",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-ec/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-ec",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-ec/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-es",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-es/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-es",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-es/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-ge",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-ge/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-gb",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-gb/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-hk",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-hk/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-hu",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-hu/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___hu-hu",
    path: indexX6Tsj9kTOCMeta?.path ?? "/hu-hu/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-au",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-au/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-mx",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-mx/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-mx",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-mx/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-ni",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-ni/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-ni",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-ni/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-pa",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-pa/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-pa",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-pa/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-pe",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-pe/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-pe",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-pe/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-pl",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-pl/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___pl-pl",
    path: indexX6Tsj9kTOCMeta?.path ?? "/pl-pl/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-pr",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-pr/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-pr",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-pr/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-pt",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-pt/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___pt-pt",
    path: indexX6Tsj9kTOCMeta?.path ?? "/pt-pt/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___en-py",
    path: indexX6Tsj9kTOCMeta?.path ?? "/en-py/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexX6Tsj9kTOCMeta?.name ?? "account___es-py",
    path: indexX6Tsj9kTOCMeta?.path ?? "/es-py/account",
    meta: indexX6Tsj9kTOCMeta || {},
    alias: indexX6Tsj9kTOCMeta?.alias || [],
    redirect: indexX6Tsj9kTOCMeta?.redirect || undefined,
    component: () => import("/data/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cXioxiEr7bMeta?.name ?? "blog-slug___en",
    path: _91slug_93cXioxiEr7bMeta?.path ?? "/en/blog/:slug()",
    meta: _91slug_93cXioxiEr7bMeta || {},
    alias: _91slug_93cXioxiEr7bMeta?.alias || [],
    redirect: _91slug_93cXioxiEr7bMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93BF43XaeoiDMeta?.name ?? "blog-authors-slug___en",
    path: _91slug_93BF43XaeoiDMeta?.path ?? "/en/blog/authors/:slug()",
    meta: _91slug_93BF43XaeoiDMeta || {},
    alias: _91slug_93BF43XaeoiDMeta?.alias || [],
    redirect: _91slug_93BF43XaeoiDMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/authors/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexjKH6jSfTSdMeta?.name ?? "blog-authors___en",
    path: indexjKH6jSfTSdMeta?.path ?? "/en/blog/authors",
    meta: indexjKH6jSfTSdMeta || {},
    alias: indexjKH6jSfTSdMeta?.alias || [],
    redirect: indexjKH6jSfTSdMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/authors/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cEB1nN3s1oMeta?.name ?? "blog-categories-slug___en",
    path: _91slug_93cEB1nN3s1oMeta?.path ?? "/en/blog/categories/:slug()",
    meta: _91slug_93cEB1nN3s1oMeta || {},
    alias: _91slug_93cEB1nN3s1oMeta?.alias || [],
    redirect: _91slug_93cEB1nN3s1oMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: index3JdQccMEcZMeta?.name ?? "blog___en",
    path: index3JdQccMEcZMeta?.path ?? "/en/blog",
    meta: index3JdQccMEcZMeta || {},
    alias: index3JdQccMEcZMeta?.alias || [],
    redirect: index3JdQccMEcZMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W2c0teDQWlMeta?.name ?? "blog-tags-slug___en",
    path: _91slug_93W2c0teDQWlMeta?.path ?? "/en/blog/tags/:slug()",
    meta: _91slug_93W2c0teDQWlMeta || {},
    alias: _91slug_93W2c0teDQWlMeta?.alias || [],
    redirect: _91slug_93W2c0teDQWlMeta?.redirect || undefined,
    component: () => import("/data/pages/blog/tags/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en",
    path: indexVag119Ai9QMeta?.path ?? "/en/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-bo",
    path: indexVag119Ai9QMeta?.path ?? "/en-bo/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-bo",
    path: indexVag119Ai9QMeta?.path ?? "/es-bo/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-bz",
    path: indexVag119Ai9QMeta?.path ?? "/en-bz/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-bz",
    path: indexVag119Ai9QMeta?.path ?? "/es-bz/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-cl",
    path: indexVag119Ai9QMeta?.path ?? "/en-cl/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-cl",
    path: indexVag119Ai9QMeta?.path ?? "/es-cl/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-co",
    path: indexVag119Ai9QMeta?.path ?? "/en-co/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-co",
    path: indexVag119Ai9QMeta?.path ?? "/es-co/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-cr",
    path: indexVag119Ai9QMeta?.path ?? "/en-cr/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-cr",
    path: indexVag119Ai9QMeta?.path ?? "/es-cr/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-ec",
    path: indexVag119Ai9QMeta?.path ?? "/en-ec/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-ec",
    path: indexVag119Ai9QMeta?.path ?? "/es-ec/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-es",
    path: indexVag119Ai9QMeta?.path ?? "/en-es/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-es",
    path: indexVag119Ai9QMeta?.path ?? "/es-es/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-ge",
    path: indexVag119Ai9QMeta?.path ?? "/en-ge/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-gb",
    path: indexVag119Ai9QMeta?.path ?? "/en-gb/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-hk",
    path: indexVag119Ai9QMeta?.path ?? "/en-hk/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-hu",
    path: indexVag119Ai9QMeta?.path ?? "/en-hu/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___hu-hu",
    path: indexVag119Ai9QMeta?.path ?? "/hu-hu/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-au",
    path: indexVag119Ai9QMeta?.path ?? "/en-au/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-mx",
    path: indexVag119Ai9QMeta?.path ?? "/en-mx/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-mx",
    path: indexVag119Ai9QMeta?.path ?? "/es-mx/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-ni",
    path: indexVag119Ai9QMeta?.path ?? "/en-ni/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-ni",
    path: indexVag119Ai9QMeta?.path ?? "/es-ni/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-pa",
    path: indexVag119Ai9QMeta?.path ?? "/en-pa/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-pa",
    path: indexVag119Ai9QMeta?.path ?? "/es-pa/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-pe",
    path: indexVag119Ai9QMeta?.path ?? "/en-pe/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-pe",
    path: indexVag119Ai9QMeta?.path ?? "/es-pe/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-pl",
    path: indexVag119Ai9QMeta?.path ?? "/en-pl/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___pl-pl",
    path: indexVag119Ai9QMeta?.path ?? "/pl-pl/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-pr",
    path: indexVag119Ai9QMeta?.path ?? "/en-pr/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-pr",
    path: indexVag119Ai9QMeta?.path ?? "/es-pr/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-pt",
    path: indexVag119Ai9QMeta?.path ?? "/en-pt/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___pt-pt",
    path: indexVag119Ai9QMeta?.path ?? "/pt-pt/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___en-py",
    path: indexVag119Ai9QMeta?.path ?? "/en-py/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexVag119Ai9QMeta?.name ?? "checkout___es-py",
    path: indexVag119Ai9QMeta?.path ?? "/es-py/checkout",
    meta: indexVag119Ai9QMeta || {},
    alias: indexVag119Ai9QMeta?.alias || [],
    redirect: indexVag119Ai9QMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-bo",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-bo/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-bo",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-bo/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-bz",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-bz/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-bz",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-bz/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-cl",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-cl/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-cl",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-cl/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-co",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-co/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-co",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-co/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-cr",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-cr/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-cr",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-cr/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-ec",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-ec/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-ec",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-ec/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-es",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-es/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-es",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-es/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-ge",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-ge/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-gb",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-gb/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-hk",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-hk/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-hu",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-hu/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___hu-hu",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/hu-hu/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-au",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-au/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-mx",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-mx/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-mx",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-mx/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-ni",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-ni/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-ni",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-ni/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-pa",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-pa/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-pa",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-pa/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-pe",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-pe/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-pe",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-pe/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-pl",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-pl/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___pl-pl",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/pl-pl/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-pr",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-pr/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-pr",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-pr/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-pt",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-pt/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___pt-pt",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/pt-pt/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___en-py",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/en-py/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youjeL9gqfYoWMeta?.name ?? "checkout-thank-you___es-py",
    path: thank_45youjeL9gqfYoWMeta?.path ?? "/es-py/checkout/thank-you",
    meta: thank_45youjeL9gqfYoWMeta || {},
    alias: thank_45youjeL9gqfYoWMeta?.alias || [],
    redirect: thank_45youjeL9gqfYoWMeta?.redirect || undefined,
    component: () => import("/data/pages/checkout/thank-you.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-bo",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-bo/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-bo",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-bo/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-bz",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-bz/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-bz",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-bz/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-cl",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-cl/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-cl",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-cl/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-co",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-co/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-co",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-co/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-cr",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-cr/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-cr",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-cr/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-ec",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-ec/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-ec",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-ec/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-es",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-es/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-es",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-es/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-ge",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-ge/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-gb",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-gb/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-hk",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-hk/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-hu",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-hu/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___hu-hu",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/hu-hu/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-au",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-au/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-mx",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-mx/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-mx",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-mx/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-ni",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-ni/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-ni",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-ni/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-pa",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-pa/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-pa",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-pa/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-pe",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-pe/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-pe",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-pe/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-pl",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-pl/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___pl-pl",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/pl-pl/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-pr",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-pr/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-pr",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-pr/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-pt",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-pt/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___pt-pt",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/pt-pt/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___en-py",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/en-py/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eL5cIth4EPMeta?.name ?? "clinics-slug___es-py",
    path: _91slug_93eL5cIth4EPMeta?.path ?? "/es-py/clinics/:slug()",
    meta: _91slug_93eL5cIth4EPMeta || {},
    alias: _91slug_93eL5cIth4EPMeta?.alias || [],
    redirect: _91slug_93eL5cIth4EPMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/[slug].vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en",
    path: index5eSz7sUw3TMeta?.path ?? "/en/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-bo",
    path: index5eSz7sUw3TMeta?.path ?? "/en-bo/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-bo",
    path: index5eSz7sUw3TMeta?.path ?? "/es-bo/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-bz",
    path: index5eSz7sUw3TMeta?.path ?? "/en-bz/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-bz",
    path: index5eSz7sUw3TMeta?.path ?? "/es-bz/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-cl",
    path: index5eSz7sUw3TMeta?.path ?? "/en-cl/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-cl",
    path: index5eSz7sUw3TMeta?.path ?? "/es-cl/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-co",
    path: index5eSz7sUw3TMeta?.path ?? "/en-co/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-co",
    path: index5eSz7sUw3TMeta?.path ?? "/es-co/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-cr",
    path: index5eSz7sUw3TMeta?.path ?? "/en-cr/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-cr",
    path: index5eSz7sUw3TMeta?.path ?? "/es-cr/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-ec",
    path: index5eSz7sUw3TMeta?.path ?? "/en-ec/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-ec",
    path: index5eSz7sUw3TMeta?.path ?? "/es-ec/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-es",
    path: index5eSz7sUw3TMeta?.path ?? "/en-es/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-es",
    path: index5eSz7sUw3TMeta?.path ?? "/es-es/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-ge",
    path: index5eSz7sUw3TMeta?.path ?? "/en-ge/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-gb",
    path: index5eSz7sUw3TMeta?.path ?? "/en-gb/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-hk",
    path: index5eSz7sUw3TMeta?.path ?? "/en-hk/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-hu",
    path: index5eSz7sUw3TMeta?.path ?? "/en-hu/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___hu-hu",
    path: index5eSz7sUw3TMeta?.path ?? "/hu-hu/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-au",
    path: index5eSz7sUw3TMeta?.path ?? "/en-au/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-mx",
    path: index5eSz7sUw3TMeta?.path ?? "/en-mx/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-mx",
    path: index5eSz7sUw3TMeta?.path ?? "/es-mx/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-ni",
    path: index5eSz7sUw3TMeta?.path ?? "/en-ni/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-ni",
    path: index5eSz7sUw3TMeta?.path ?? "/es-ni/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-pa",
    path: index5eSz7sUw3TMeta?.path ?? "/en-pa/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-pa",
    path: index5eSz7sUw3TMeta?.path ?? "/es-pa/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-pe",
    path: index5eSz7sUw3TMeta?.path ?? "/en-pe/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-pe",
    path: index5eSz7sUw3TMeta?.path ?? "/es-pe/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-pl",
    path: index5eSz7sUw3TMeta?.path ?? "/en-pl/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___pl-pl",
    path: index5eSz7sUw3TMeta?.path ?? "/pl-pl/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-pr",
    path: index5eSz7sUw3TMeta?.path ?? "/en-pr/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-pr",
    path: index5eSz7sUw3TMeta?.path ?? "/es-pr/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-pt",
    path: index5eSz7sUw3TMeta?.path ?? "/en-pt/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___pt-pt",
    path: index5eSz7sUw3TMeta?.path ?? "/pt-pt/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___en-py",
    path: index5eSz7sUw3TMeta?.path ?? "/en-py/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: index5eSz7sUw3TMeta?.name ?? "clinics___es-py",
    path: index5eSz7sUw3TMeta?.path ?? "/es-py/clinics",
    meta: index5eSz7sUw3TMeta || {},
    alias: index5eSz7sUw3TMeta?.alias || [],
    redirect: index5eSz7sUw3TMeta?.redirect || undefined,
    component: () => import("/data/pages/clinics/index.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-bo",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-bo/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-bo",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-bo/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-bz",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-bz/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-bz",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-bz/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-cl",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-cl/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-cl",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-cl/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-co",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-co/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-co",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-co/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-cr",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-cr/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-cr",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-cr/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-ec",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-ec/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-ec",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-ec/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-es",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-es/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-es",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-es/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-ge",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-ge/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-gb",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-gb/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-hk",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-hk/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-hu",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-hu/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___hu-hu",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/hu-hu/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-au",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-au/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-mx",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-mx/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-mx",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-mx/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-ni",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-ni/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-ni",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-ni/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-pa",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-pa/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-pa",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-pa/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-pe",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-pe/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-pe",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-pe/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-pl",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-pl/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___pl-pl",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/pl-pl/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-pr",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-pr/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-pr",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-pr/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-pt",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-pt/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___pt-pt",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/pt-pt/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___en-py",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/en-py/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usVb7LZb4YQxMeta?.name ?? "contact-us___es-py",
    path: contact_45usVb7LZb4YQxMeta?.path ?? "/es-py/contact-us",
    meta: contact_45usVb7LZb4YQxMeta || {},
    alias: contact_45usVb7LZb4YQxMeta?.alias || [],
    redirect: contact_45usVb7LZb4YQxMeta?.redirect || undefined,
    component: () => import("/data/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en",
    path: indexojFm3fXhJiMeta?.path ?? "/en/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-bo",
    path: indexojFm3fXhJiMeta?.path ?? "/en-bo/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-bo",
    path: indexojFm3fXhJiMeta?.path ?? "/es-bo/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-bz",
    path: indexojFm3fXhJiMeta?.path ?? "/en-bz/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-bz",
    path: indexojFm3fXhJiMeta?.path ?? "/es-bz/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-cl",
    path: indexojFm3fXhJiMeta?.path ?? "/en-cl/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-cl",
    path: indexojFm3fXhJiMeta?.path ?? "/es-cl/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-co",
    path: indexojFm3fXhJiMeta?.path ?? "/en-co/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-co",
    path: indexojFm3fXhJiMeta?.path ?? "/es-co/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-cr",
    path: indexojFm3fXhJiMeta?.path ?? "/en-cr/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-cr",
    path: indexojFm3fXhJiMeta?.path ?? "/es-cr/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-ec",
    path: indexojFm3fXhJiMeta?.path ?? "/en-ec/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-ec",
    path: indexojFm3fXhJiMeta?.path ?? "/es-ec/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-es",
    path: indexojFm3fXhJiMeta?.path ?? "/en-es/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-es",
    path: indexojFm3fXhJiMeta?.path ?? "/es-es/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-ge",
    path: indexojFm3fXhJiMeta?.path ?? "/en-ge/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-gb",
    path: indexojFm3fXhJiMeta?.path ?? "/en-gb/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-hk",
    path: indexojFm3fXhJiMeta?.path ?? "/en-hk/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-hu",
    path: indexojFm3fXhJiMeta?.path ?? "/en-hu/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___hu-hu",
    path: indexojFm3fXhJiMeta?.path ?? "/hu-hu/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-au",
    path: indexojFm3fXhJiMeta?.path ?? "/en-au/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-mx",
    path: indexojFm3fXhJiMeta?.path ?? "/en-mx/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-mx",
    path: indexojFm3fXhJiMeta?.path ?? "/es-mx/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-ni",
    path: indexojFm3fXhJiMeta?.path ?? "/en-ni/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-ni",
    path: indexojFm3fXhJiMeta?.path ?? "/es-ni/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-pa",
    path: indexojFm3fXhJiMeta?.path ?? "/en-pa/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-pa",
    path: indexojFm3fXhJiMeta?.path ?? "/es-pa/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-pe",
    path: indexojFm3fXhJiMeta?.path ?? "/en-pe/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-pe",
    path: indexojFm3fXhJiMeta?.path ?? "/es-pe/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-pl",
    path: indexojFm3fXhJiMeta?.path ?? "/en-pl/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___pl-pl",
    path: indexojFm3fXhJiMeta?.path ?? "/pl-pl/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-pr",
    path: indexojFm3fXhJiMeta?.path ?? "/en-pr/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-pr",
    path: indexojFm3fXhJiMeta?.path ?? "/es-pr/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-pt",
    path: indexojFm3fXhJiMeta?.path ?? "/en-pt/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___pt-pt",
    path: indexojFm3fXhJiMeta?.path ?? "/pt-pt/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___en-py",
    path: indexojFm3fXhJiMeta?.path ?? "/en-py/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: indexojFm3fXhJiMeta?.name ?? "direct-payment-order_token___es-py",
    path: indexojFm3fXhJiMeta?.path ?? "/es-py/direct-payment/:order_token()",
    meta: indexojFm3fXhJiMeta || {},
    alias: indexojFm3fXhJiMeta?.alias || [],
    redirect: indexojFm3fXhJiMeta?.redirect || undefined,
    component: () => import("/data/pages/direct-payment/[order_token]/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-bo",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-bo/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-bo",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-bo/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-bz",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-bz/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-bz",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-bz/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-cl",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-cl/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-cl",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-cl/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-co",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-co/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-co",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-co/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-cr",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-cr/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-cr",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-cr/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-ec",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-ec/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-ec",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-ec/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-es",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-es/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-es",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-es/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-ge",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-ge/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-gb",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-gb/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-hk",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-hk/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-hu",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-hu/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___hu-hu",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/hu-hu/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-au",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-au/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-mx",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-mx/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-mx",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-mx/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-ni",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-ni/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-ni",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-ni/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-pa",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-pa/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-pa",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-pa/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-pe",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-pe/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-pe",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-pe/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-pl",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-pl/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___pl-pl",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/pl-pl/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-pr",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-pr/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-pr",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-pr/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-pt",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-pt/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___pt-pt",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/pt-pt/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___en-py",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/en-py/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93wLojpWP9YDMeta?.name ?? "faqs-slug___es-py",
    path: _91slug_93wLojpWP9YDMeta?.path ?? "/es-py/faqs/:slug()",
    meta: _91slug_93wLojpWP9YDMeta || {},
    alias: _91slug_93wLojpWP9YDMeta?.alias || [],
    redirect: _91slug_93wLojpWP9YDMeta?.redirect || undefined,
    component: () => import("/data/pages/faqs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en",
    path: indexLfZweG7Ef2Meta?.path ?? "/en/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-bo",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-bo/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-bo",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-bo/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-bz",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-bz/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-bz",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-bz/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-cl",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-cl/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-cl",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-cl/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-co",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-co/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-co",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-co/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-cr",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-cr/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-cr",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-cr/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-ec",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-ec/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-ec",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-ec/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-es",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-es/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-es",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-es/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-ge",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-ge/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-gb",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-gb/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-hk",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-hk/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-hu",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-hu/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___hu-hu",
    path: indexLfZweG7Ef2Meta?.path ?? "/hu-hu/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-au",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-au/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-mx",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-mx/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-mx",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-mx/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-ni",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-ni/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-ni",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-ni/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-pa",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-pa/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-pa",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-pa/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-pe",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-pe/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-pe",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-pe/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-pl",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-pl/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___pl-pl",
    path: indexLfZweG7Ef2Meta?.path ?? "/pl-pl/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-pr",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-pr/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-pr",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-pr/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-pt",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-pt/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___pt-pt",
    path: indexLfZweG7Ef2Meta?.path ?? "/pt-pt/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___en-py",
    path: indexLfZweG7Ef2Meta?.path ?? "/en-py/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexLfZweG7Ef2Meta?.name ?? "faqs___es-py",
    path: indexLfZweG7Ef2Meta?.path ?? "/es-py/faqs",
    meta: indexLfZweG7Ef2Meta || {},
    alias: indexLfZweG7Ef2Meta?.alias || [],
    redirect: indexLfZweG7Ef2Meta?.redirect || undefined,
    component: () => import("/data/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en",
    path: index0sTCdVluXDMeta?.path ?? "/en/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-bo",
    path: index0sTCdVluXDMeta?.path ?? "/en-bo/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-bo",
    path: index0sTCdVluXDMeta?.path ?? "/es-bo/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-bz",
    path: index0sTCdVluXDMeta?.path ?? "/en-bz/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-bz",
    path: index0sTCdVluXDMeta?.path ?? "/es-bz/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-cl",
    path: index0sTCdVluXDMeta?.path ?? "/en-cl/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-cl",
    path: index0sTCdVluXDMeta?.path ?? "/es-cl/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-co",
    path: index0sTCdVluXDMeta?.path ?? "/en-co/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-co",
    path: index0sTCdVluXDMeta?.path ?? "/es-co/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-cr",
    path: index0sTCdVluXDMeta?.path ?? "/en-cr/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-cr",
    path: index0sTCdVluXDMeta?.path ?? "/es-cr/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-ec",
    path: index0sTCdVluXDMeta?.path ?? "/en-ec/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-ec",
    path: index0sTCdVluXDMeta?.path ?? "/es-ec/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-es",
    path: index0sTCdVluXDMeta?.path ?? "/en-es/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-es",
    path: index0sTCdVluXDMeta?.path ?? "/es-es/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-ge",
    path: index0sTCdVluXDMeta?.path ?? "/en-ge/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-gb",
    path: index0sTCdVluXDMeta?.path ?? "/en-gb/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-hk",
    path: index0sTCdVluXDMeta?.path ?? "/en-hk/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-hu",
    path: index0sTCdVluXDMeta?.path ?? "/en-hu/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___hu-hu",
    path: index0sTCdVluXDMeta?.path ?? "/hu-hu/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-au",
    path: index0sTCdVluXDMeta?.path ?? "/en-au/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-mx",
    path: index0sTCdVluXDMeta?.path ?? "/en-mx/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-mx",
    path: index0sTCdVluXDMeta?.path ?? "/es-mx/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-ni",
    path: index0sTCdVluXDMeta?.path ?? "/en-ni/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-ni",
    path: index0sTCdVluXDMeta?.path ?? "/es-ni/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-pa",
    path: index0sTCdVluXDMeta?.path ?? "/en-pa/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-pa",
    path: index0sTCdVluXDMeta?.path ?? "/es-pa/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-pe",
    path: index0sTCdVluXDMeta?.path ?? "/en-pe/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-pe",
    path: index0sTCdVluXDMeta?.path ?? "/es-pe/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-pl",
    path: index0sTCdVluXDMeta?.path ?? "/en-pl/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___pl-pl",
    path: index0sTCdVluXDMeta?.path ?? "/pl-pl/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-pr",
    path: index0sTCdVluXDMeta?.path ?? "/en-pr/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-pr",
    path: index0sTCdVluXDMeta?.path ?? "/es-pr/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-pt",
    path: index0sTCdVluXDMeta?.path ?? "/en-pt/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___pt-pt",
    path: index0sTCdVluXDMeta?.path ?? "/pt-pt/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___en-py",
    path: index0sTCdVluXDMeta?.path ?? "/en-py/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: index0sTCdVluXDMeta?.name ?? "get-started-section-service-category___es-py",
    path: index0sTCdVluXDMeta?.path ?? "/es-py/get-started/:section?/:service?/:category?",
    meta: index0sTCdVluXDMeta || {},
    alias: index0sTCdVluXDMeta?.alias || [],
    redirect: index0sTCdVluXDMeta?.redirect || undefined,
    component: () => import("/data/pages/get-started/[[section]]/[[service]]/[[category]]/index.vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-bo",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-bo/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-bo",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-bo/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-bz",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-bz/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-bz",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-bz/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-cl",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-cl/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-cl",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-cl/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-co",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-co/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-co",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-co/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-cr",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-cr/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-cr",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-cr/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-ec",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-ec/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-ec",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-ec/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-es",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-es/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-es",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-es/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-ge",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-ge/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-gb",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-gb/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-hk",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-hk/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-hu",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-hu/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___hu-hu",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/hu-hu/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-au",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-au/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-mx",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-mx/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-mx",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-mx/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-ni",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-ni/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-ni",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-ni/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-pa",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-pa/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-pa",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-pa/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-pe",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-pe/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-pe",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-pe/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-pl",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-pl/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___pl-pl",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/pl-pl/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-pr",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-pr/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-pr",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-pr/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-pt",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-pt/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___pt-pt",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/pt-pt/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___en-py",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/en-py/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: _91condition_93IYA8PUM8TrMeta?.name ?? "help-and-advice-category-slug-condition___es-py",
    path: _91condition_93IYA8PUM8TrMeta?.path ?? "/es-py/help-and-advice/:category()/:slug()/:condition()",
    meta: _91condition_93IYA8PUM8TrMeta || {},
    alias: _91condition_93IYA8PUM8TrMeta?.alias || [],
    redirect: _91condition_93IYA8PUM8TrMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/[condition].vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en",
    path: indexZeiT6zQX5VMeta?.path ?? "/en/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-bo",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-bo/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-bo",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-bo/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-bz",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-bz/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-bz",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-bz/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-cl",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-cl/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-cl",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-cl/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-co",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-co/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-co",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-co/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-cr",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-cr/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-cr",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-cr/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-ec",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-ec/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-ec",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-ec/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-es",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-es/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-es",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-es/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-ge",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-ge/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-gb",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-gb/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-hk",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-hk/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-hu",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-hu/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___hu-hu",
    path: indexZeiT6zQX5VMeta?.path ?? "/hu-hu/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-au",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-au/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-mx",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-mx/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-mx",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-mx/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-ni",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-ni/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-ni",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-ni/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-pa",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-pa/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-pa",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-pa/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-pe",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-pe/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-pe",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-pe/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-pl",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-pl/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___pl-pl",
    path: indexZeiT6zQX5VMeta?.path ?? "/pl-pl/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-pr",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-pr/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-pr",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-pr/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-pt",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-pt/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___pt-pt",
    path: indexZeiT6zQX5VMeta?.path ?? "/pt-pt/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___en-py",
    path: indexZeiT6zQX5VMeta?.path ?? "/en-py/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexZeiT6zQX5VMeta?.name ?? "help-and-advice-category-slug___es-py",
    path: indexZeiT6zQX5VMeta?.path ?? "/es-py/help-and-advice/:category()/:slug()",
    meta: indexZeiT6zQX5VMeta || {},
    alias: indexZeiT6zQX5VMeta?.alias || [],
    redirect: indexZeiT6zQX5VMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en",
    path: index9wTWHSpoWsMeta?.path ?? "/en/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-bo",
    path: index9wTWHSpoWsMeta?.path ?? "/en-bo/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-bo",
    path: index9wTWHSpoWsMeta?.path ?? "/es-bo/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-bz",
    path: index9wTWHSpoWsMeta?.path ?? "/en-bz/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-bz",
    path: index9wTWHSpoWsMeta?.path ?? "/es-bz/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-cl",
    path: index9wTWHSpoWsMeta?.path ?? "/en-cl/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-cl",
    path: index9wTWHSpoWsMeta?.path ?? "/es-cl/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-co",
    path: index9wTWHSpoWsMeta?.path ?? "/en-co/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-co",
    path: index9wTWHSpoWsMeta?.path ?? "/es-co/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-cr",
    path: index9wTWHSpoWsMeta?.path ?? "/en-cr/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-cr",
    path: index9wTWHSpoWsMeta?.path ?? "/es-cr/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-ec",
    path: index9wTWHSpoWsMeta?.path ?? "/en-ec/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-ec",
    path: index9wTWHSpoWsMeta?.path ?? "/es-ec/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-es",
    path: index9wTWHSpoWsMeta?.path ?? "/en-es/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-es",
    path: index9wTWHSpoWsMeta?.path ?? "/es-es/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-ge",
    path: index9wTWHSpoWsMeta?.path ?? "/en-ge/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-gb",
    path: index9wTWHSpoWsMeta?.path ?? "/en-gb/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-hk",
    path: index9wTWHSpoWsMeta?.path ?? "/en-hk/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-hu",
    path: index9wTWHSpoWsMeta?.path ?? "/en-hu/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___hu-hu",
    path: index9wTWHSpoWsMeta?.path ?? "/hu-hu/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-au",
    path: index9wTWHSpoWsMeta?.path ?? "/en-au/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-mx",
    path: index9wTWHSpoWsMeta?.path ?? "/en-mx/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-mx",
    path: index9wTWHSpoWsMeta?.path ?? "/es-mx/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-ni",
    path: index9wTWHSpoWsMeta?.path ?? "/en-ni/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-ni",
    path: index9wTWHSpoWsMeta?.path ?? "/es-ni/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-pa",
    path: index9wTWHSpoWsMeta?.path ?? "/en-pa/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-pa",
    path: index9wTWHSpoWsMeta?.path ?? "/es-pa/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-pe",
    path: index9wTWHSpoWsMeta?.path ?? "/en-pe/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-pe",
    path: index9wTWHSpoWsMeta?.path ?? "/es-pe/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-pl",
    path: index9wTWHSpoWsMeta?.path ?? "/en-pl/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___pl-pl",
    path: index9wTWHSpoWsMeta?.path ?? "/pl-pl/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-pr",
    path: index9wTWHSpoWsMeta?.path ?? "/en-pr/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-pr",
    path: index9wTWHSpoWsMeta?.path ?? "/es-pr/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-pt",
    path: index9wTWHSpoWsMeta?.path ?? "/en-pt/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___pt-pt",
    path: index9wTWHSpoWsMeta?.path ?? "/pt-pt/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___en-py",
    path: index9wTWHSpoWsMeta?.path ?? "/en-py/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index9wTWHSpoWsMeta?.name ?? "help-and-advice-category___es-py",
    path: index9wTWHSpoWsMeta?.path ?? "/es-py/help-and-advice/:category()",
    meta: index9wTWHSpoWsMeta || {},
    alias: index9wTWHSpoWsMeta?.alias || [],
    redirect: index9wTWHSpoWsMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en",
    path: indexjAaK7ryr1uMeta?.path ?? "/en/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-bo",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-bo/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-bo",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-bo/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-bz",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-bz/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-bz",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-bz/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-cl",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-cl/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-cl",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-cl/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-co",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-co/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-co",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-co/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-cr",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-cr/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-cr",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-cr/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-ec",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-ec/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-ec",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-ec/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-es",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-es/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-es",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-es/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-ge",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-ge/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-gb",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-gb/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-hk",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-hk/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-hu",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-hu/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___hu-hu",
    path: indexjAaK7ryr1uMeta?.path ?? "/hu-hu/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-au",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-au/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-mx",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-mx/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-mx",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-mx/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-ni",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-ni/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-ni",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-ni/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-pa",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-pa/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-pa",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-pa/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-pe",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-pe/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-pe",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-pe/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-pl",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-pl/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___pl-pl",
    path: indexjAaK7ryr1uMeta?.path ?? "/pl-pl/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-pr",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-pr/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-pr",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-pr/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-pt",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-pt/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___pt-pt",
    path: indexjAaK7ryr1uMeta?.path ?? "/pt-pt/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___en-py",
    path: indexjAaK7ryr1uMeta?.path ?? "/en-py/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexjAaK7ryr1uMeta?.name ?? "help-and-advice___es-py",
    path: indexjAaK7ryr1uMeta?.path ?? "/es-py/help-and-advice",
    meta: indexjAaK7ryr1uMeta || {},
    alias: indexjAaK7ryr1uMeta?.alias || [],
    redirect: indexjAaK7ryr1uMeta?.redirect || undefined,
    component: () => import("/data/pages/help-and-advice/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en",
    path: indexwJM0pWSZGwMeta?.path ?? "/en",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-bo",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-bo",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-bo",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-bo",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-bz",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-bz",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-bz",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-bz",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-cl",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-cl",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-cl",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-cl",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-co",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-co",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-co",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-co",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-cr",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-cr",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-cr",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-cr",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-ec",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-ec",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-ec",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-ec",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-es",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-es",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-es",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-es",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-ge",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-ge",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-gb",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-gb",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-hk",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-hk",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-hu",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-hu",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___hu-hu",
    path: indexwJM0pWSZGwMeta?.path ?? "/hu-hu",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-au",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-au",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-mx",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-mx",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-mx",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-mx",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-ni",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-ni",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-ni",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-ni",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-pa",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-pa",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-pa",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-pa",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-pe",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-pe",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-pe",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-pe",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-pl",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-pl",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___pl-pl",
    path: indexwJM0pWSZGwMeta?.path ?? "/pl-pl",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-pr",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-pr",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-pr",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-pr",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-pt",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-pt",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___pt-pt",
    path: indexwJM0pWSZGwMeta?.path ?? "/pt-pt",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___en-py",
    path: indexwJM0pWSZGwMeta?.path ?? "/en-py",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwJM0pWSZGwMeta?.name ?? "index___es-py",
    path: indexwJM0pWSZGwMeta?.path ?? "/es-py",
    meta: indexwJM0pWSZGwMeta || {},
    alias: indexwJM0pWSZGwMeta?.alias || [],
    redirect: indexwJM0pWSZGwMeta?.redirect || undefined,
    component: () => import("/data/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-bo",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-bo/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-bo",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-bo/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-bz",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-bz/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-bz",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-bz/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-cl",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-cl/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-cl",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-cl/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-co",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-co/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-co",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-co/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-cr",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-cr/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-cr",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-cr/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-ec",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-ec/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-ec",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-ec/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-es",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-es/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-es",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-es/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-ge",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-ge/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-gb",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-gb/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-hk",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-hk/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-hu",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-hu/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___hu-hu",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/hu-hu/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-au",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-au/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-mx",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-mx/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-mx",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-mx/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-ni",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-ni/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-ni",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-ni/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-pa",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-pa/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-pa",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-pa/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-pe",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-pe/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-pe",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-pe/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-pl",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-pl/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___pl-pl",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/pl-pl/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-pr",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-pr/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-pr",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-pr/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-pt",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-pt/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___pt-pt",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/pt-pt/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___en-py",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/en-py/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VAORsljsIBMeta?.name ?? "legal-and-privacy-slug___es-py",
    path: _91slug_93VAORsljsIBMeta?.path ?? "/es-py/legal-and-privacy/:slug()",
    meta: _91slug_93VAORsljsIBMeta || {},
    alias: _91slug_93VAORsljsIBMeta?.alias || [],
    redirect: _91slug_93VAORsljsIBMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en",
    path: indexSWPrnbyjTJMeta?.path ?? "/en/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-bo",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-bo/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-bo",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-bo/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-bz",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-bz/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-bz",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-bz/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-cl",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-cl/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-cl",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-cl/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-co",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-co/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-co",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-co/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-cr",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-cr/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-cr",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-cr/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-ec",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-ec/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-ec",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-ec/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-es",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-es/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-es",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-es/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-ge",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-ge/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-gb",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-gb/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-hk",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-hk/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-hu",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-hu/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___hu-hu",
    path: indexSWPrnbyjTJMeta?.path ?? "/hu-hu/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-au",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-au/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-mx",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-mx/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-mx",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-mx/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-ni",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-ni/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-ni",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-ni/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-pa",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-pa/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-pa",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-pa/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-pe",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-pe/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-pe",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-pe/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-pl",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-pl/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___pl-pl",
    path: indexSWPrnbyjTJMeta?.path ?? "/pl-pl/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-pr",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-pr/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-pr",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-pr/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-pt",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-pt/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___pt-pt",
    path: indexSWPrnbyjTJMeta?.path ?? "/pt-pt/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___en-py",
    path: indexSWPrnbyjTJMeta?.path ?? "/en-py/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: indexSWPrnbyjTJMeta?.name ?? "legal-and-privacy___es-py",
    path: indexSWPrnbyjTJMeta?.path ?? "/es-py/legal-and-privacy",
    meta: indexSWPrnbyjTJMeta || {},
    alias: indexSWPrnbyjTJMeta?.alias || [],
    redirect: indexSWPrnbyjTJMeta?.redirect || undefined,
    component: () => import("/data/pages/legal-and-privacy/index.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-bo",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-bo/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-bo",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-bo/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-bz",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-bz/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-bz",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-bz/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-cl",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-cl/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-cl",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-cl/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-co",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-co/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-co",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-co/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-cr",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-cr/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-cr",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-cr/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-ec",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-ec/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-ec",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-ec/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-es",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-es/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-es",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-es/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-ge",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-ge/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-gb",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-gb/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-hk",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-hk/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-hu",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-hu/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___hu-hu",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/hu-hu/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-au",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-au/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-mx",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-mx/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-mx",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-mx/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-ni",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-ni/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-ni",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-ni/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-pa",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-pa/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-pa",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-pa/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-pe",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-pe/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-pe",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-pe/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-pl",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-pl/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___pl-pl",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/pl-pl/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-pr",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-pr/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-pr",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-pr/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-pt",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-pt/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___pt-pt",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/pt-pt/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___en-py",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/en-py/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: policy_45detailtnulJGjISmMeta?.name ?? "policy-detail___es-py",
    path: policy_45detailtnulJGjISmMeta?.path ?? "/es-py/policy-detail",
    meta: policy_45detailtnulJGjISmMeta || {},
    alias: policy_45detailtnulJGjISmMeta?.alias || [],
    redirect: policy_45detailtnulJGjISmMeta?.redirect || undefined,
    component: () => import("/data/pages/policy-detail.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en",
    path: index2QwGis8PcNMeta?.path ?? "/en/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-bo",
    path: index2QwGis8PcNMeta?.path ?? "/en-bo/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-bo",
    path: index2QwGis8PcNMeta?.path ?? "/es-bo/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-bz",
    path: index2QwGis8PcNMeta?.path ?? "/en-bz/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-bz",
    path: index2QwGis8PcNMeta?.path ?? "/es-bz/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-cl",
    path: index2QwGis8PcNMeta?.path ?? "/en-cl/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-cl",
    path: index2QwGis8PcNMeta?.path ?? "/es-cl/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-co",
    path: index2QwGis8PcNMeta?.path ?? "/en-co/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-co",
    path: index2QwGis8PcNMeta?.path ?? "/es-co/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-cr",
    path: index2QwGis8PcNMeta?.path ?? "/en-cr/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-cr",
    path: index2QwGis8PcNMeta?.path ?? "/es-cr/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-ec",
    path: index2QwGis8PcNMeta?.path ?? "/en-ec/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-ec",
    path: index2QwGis8PcNMeta?.path ?? "/es-ec/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-es",
    path: index2QwGis8PcNMeta?.path ?? "/en-es/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-es",
    path: index2QwGis8PcNMeta?.path ?? "/es-es/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-ge",
    path: index2QwGis8PcNMeta?.path ?? "/en-ge/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-gb",
    path: index2QwGis8PcNMeta?.path ?? "/en-gb/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-hk",
    path: index2QwGis8PcNMeta?.path ?? "/en-hk/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-hu",
    path: index2QwGis8PcNMeta?.path ?? "/en-hu/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___hu-hu",
    path: index2QwGis8PcNMeta?.path ?? "/hu-hu/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-au",
    path: index2QwGis8PcNMeta?.path ?? "/en-au/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-mx",
    path: index2QwGis8PcNMeta?.path ?? "/en-mx/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-mx",
    path: index2QwGis8PcNMeta?.path ?? "/es-mx/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-ni",
    path: index2QwGis8PcNMeta?.path ?? "/en-ni/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-ni",
    path: index2QwGis8PcNMeta?.path ?? "/es-ni/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-pa",
    path: index2QwGis8PcNMeta?.path ?? "/en-pa/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-pa",
    path: index2QwGis8PcNMeta?.path ?? "/es-pa/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-pe",
    path: index2QwGis8PcNMeta?.path ?? "/en-pe/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-pe",
    path: index2QwGis8PcNMeta?.path ?? "/es-pe/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-pl",
    path: index2QwGis8PcNMeta?.path ?? "/en-pl/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___pl-pl",
    path: index2QwGis8PcNMeta?.path ?? "/pl-pl/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-pr",
    path: index2QwGis8PcNMeta?.path ?? "/en-pr/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-pr",
    path: index2QwGis8PcNMeta?.path ?? "/es-pr/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-pt",
    path: index2QwGis8PcNMeta?.path ?? "/en-pt/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___pt-pt",
    path: index2QwGis8PcNMeta?.path ?? "/pt-pt/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___en-py",
    path: index2QwGis8PcNMeta?.path ?? "/en-py/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: index2QwGis8PcNMeta?.name ?? "reviews___es-py",
    path: index2QwGis8PcNMeta?.path ?? "/es-py/reviews",
    meta: index2QwGis8PcNMeta || {},
    alias: index2QwGis8PcNMeta?.alias || [],
    redirect: index2QwGis8PcNMeta?.redirect || undefined,
    component: () => import("/data/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en",
    path: indexjNcQzb30nMMeta?.path ?? "/en/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-bo",
    path: indexjNcQzb30nMMeta?.path ?? "/en-bo/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-bo",
    path: indexjNcQzb30nMMeta?.path ?? "/es-bo/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-bz",
    path: indexjNcQzb30nMMeta?.path ?? "/en-bz/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-bz",
    path: indexjNcQzb30nMMeta?.path ?? "/es-bz/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-cl",
    path: indexjNcQzb30nMMeta?.path ?? "/en-cl/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-cl",
    path: indexjNcQzb30nMMeta?.path ?? "/es-cl/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-co",
    path: indexjNcQzb30nMMeta?.path ?? "/en-co/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-co",
    path: indexjNcQzb30nMMeta?.path ?? "/es-co/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-cr",
    path: indexjNcQzb30nMMeta?.path ?? "/en-cr/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-cr",
    path: indexjNcQzb30nMMeta?.path ?? "/es-cr/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-ec",
    path: indexjNcQzb30nMMeta?.path ?? "/en-ec/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-ec",
    path: indexjNcQzb30nMMeta?.path ?? "/es-ec/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-es",
    path: indexjNcQzb30nMMeta?.path ?? "/en-es/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-es",
    path: indexjNcQzb30nMMeta?.path ?? "/es-es/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-ge",
    path: indexjNcQzb30nMMeta?.path ?? "/en-ge/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-gb",
    path: indexjNcQzb30nMMeta?.path ?? "/en-gb/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-hk",
    path: indexjNcQzb30nMMeta?.path ?? "/en-hk/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-hu",
    path: indexjNcQzb30nMMeta?.path ?? "/en-hu/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___hu-hu",
    path: indexjNcQzb30nMMeta?.path ?? "/hu-hu/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-au",
    path: indexjNcQzb30nMMeta?.path ?? "/en-au/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-mx",
    path: indexjNcQzb30nMMeta?.path ?? "/en-mx/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-mx",
    path: indexjNcQzb30nMMeta?.path ?? "/es-mx/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-ni",
    path: indexjNcQzb30nMMeta?.path ?? "/en-ni/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-ni",
    path: indexjNcQzb30nMMeta?.path ?? "/es-ni/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-pa",
    path: indexjNcQzb30nMMeta?.path ?? "/en-pa/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-pa",
    path: indexjNcQzb30nMMeta?.path ?? "/es-pa/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-pe",
    path: indexjNcQzb30nMMeta?.path ?? "/en-pe/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-pe",
    path: indexjNcQzb30nMMeta?.path ?? "/es-pe/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-pl",
    path: indexjNcQzb30nMMeta?.path ?? "/en-pl/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___pl-pl",
    path: indexjNcQzb30nMMeta?.path ?? "/pl-pl/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-pr",
    path: indexjNcQzb30nMMeta?.path ?? "/en-pr/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-pr",
    path: indexjNcQzb30nMMeta?.path ?? "/es-pr/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-pt",
    path: indexjNcQzb30nMMeta?.path ?? "/en-pt/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___pt-pt",
    path: indexjNcQzb30nMMeta?.path ?? "/pt-pt/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___en-py",
    path: indexjNcQzb30nMMeta?.path ?? "/en-py/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexjNcQzb30nMMeta?.name ?? "tests-id-slug___es-py",
    path: indexjNcQzb30nMMeta?.path ?? "/es-py/tests/:id()/:slug()",
    meta: indexjNcQzb30nMMeta || {},
    alias: indexjNcQzb30nMMeta?.alias || [],
    redirect: indexjNcQzb30nMMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en",
    path: index1s4p7SZOGtMeta?.path ?? "/en/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-bo",
    path: index1s4p7SZOGtMeta?.path ?? "/en-bo/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-bo",
    path: index1s4p7SZOGtMeta?.path ?? "/es-bo/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-bz",
    path: index1s4p7SZOGtMeta?.path ?? "/en-bz/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-bz",
    path: index1s4p7SZOGtMeta?.path ?? "/es-bz/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-cl",
    path: index1s4p7SZOGtMeta?.path ?? "/en-cl/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-cl",
    path: index1s4p7SZOGtMeta?.path ?? "/es-cl/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-co",
    path: index1s4p7SZOGtMeta?.path ?? "/en-co/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-co",
    path: index1s4p7SZOGtMeta?.path ?? "/es-co/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-cr",
    path: index1s4p7SZOGtMeta?.path ?? "/en-cr/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-cr",
    path: index1s4p7SZOGtMeta?.path ?? "/es-cr/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-ec",
    path: index1s4p7SZOGtMeta?.path ?? "/en-ec/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-ec",
    path: index1s4p7SZOGtMeta?.path ?? "/es-ec/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-es",
    path: index1s4p7SZOGtMeta?.path ?? "/en-es/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-es",
    path: index1s4p7SZOGtMeta?.path ?? "/es-es/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-ge",
    path: index1s4p7SZOGtMeta?.path ?? "/en-ge/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-gb",
    path: index1s4p7SZOGtMeta?.path ?? "/en-gb/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-hk",
    path: index1s4p7SZOGtMeta?.path ?? "/en-hk/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-hu",
    path: index1s4p7SZOGtMeta?.path ?? "/en-hu/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___hu-hu",
    path: index1s4p7SZOGtMeta?.path ?? "/hu-hu/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-au",
    path: index1s4p7SZOGtMeta?.path ?? "/en-au/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-mx",
    path: index1s4p7SZOGtMeta?.path ?? "/en-mx/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-mx",
    path: index1s4p7SZOGtMeta?.path ?? "/es-mx/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-ni",
    path: index1s4p7SZOGtMeta?.path ?? "/en-ni/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-ni",
    path: index1s4p7SZOGtMeta?.path ?? "/es-ni/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-pa",
    path: index1s4p7SZOGtMeta?.path ?? "/en-pa/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-pa",
    path: index1s4p7SZOGtMeta?.path ?? "/es-pa/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-pe",
    path: index1s4p7SZOGtMeta?.path ?? "/en-pe/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-pe",
    path: index1s4p7SZOGtMeta?.path ?? "/es-pe/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-pl",
    path: index1s4p7SZOGtMeta?.path ?? "/en-pl/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___pl-pl",
    path: index1s4p7SZOGtMeta?.path ?? "/pl-pl/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-pr",
    path: index1s4p7SZOGtMeta?.path ?? "/en-pr/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-pr",
    path: index1s4p7SZOGtMeta?.path ?? "/es-pr/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-pt",
    path: index1s4p7SZOGtMeta?.path ?? "/en-pt/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___pt-pt",
    path: index1s4p7SZOGtMeta?.path ?? "/pt-pt/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___en-py",
    path: index1s4p7SZOGtMeta?.path ?? "/en-py/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1s4p7SZOGtMeta?.name ?? "tests-id___es-py",
    path: index1s4p7SZOGtMeta?.path ?? "/es-py/tests/:id()",
    meta: index1s4p7SZOGtMeta || {},
    alias: index1s4p7SZOGtMeta?.alias || [],
    redirect: index1s4p7SZOGtMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-bo",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-bo/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-bo",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-bo/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-bz",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-bz/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-bz",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-bz/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-cl",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-cl/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-cl",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-cl/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-co",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-co/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-co",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-co/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-cr",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-cr/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-cr",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-cr/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-ec",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-ec/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-ec",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-ec/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-es",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-es/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-es",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-es/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-ge",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-ge/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-gb",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-gb/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-hk",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-hk/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-hu",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-hu/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___hu-hu",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/hu-hu/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-au",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-au/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-mx",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-mx/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-mx",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-mx/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-ni",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-ni/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-ni",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-ni/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-pa",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-pa/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-pa",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-pa/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-pe",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-pe/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-pe",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-pe/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-pl",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-pl/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___pl-pl",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/pl-pl/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-pr",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-pr/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-pr",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-pr/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-pt",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-pt/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___pt-pt",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/pt-pt/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___en-py",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/en-py/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ipsotPlbY8Meta?.name ?? "tests-health-and-wellness-id___es-py",
    path: _91id_93ipsotPlbY8Meta?.path ?? "/es-py/tests/health-and-wellness/:id()",
    meta: _91id_93ipsotPlbY8Meta || {},
    alias: _91id_93ipsotPlbY8Meta?.alias || [],
    redirect: _91id_93ipsotPlbY8Meta?.redirect || undefined,
    component: () => import("/data/pages/tests/health-and-wellness/[id].vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en",
    path: indexi5j1BAjR1wMeta?.path ?? "/en/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-bo",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-bo/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-bo",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-bo/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-bz",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-bz/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-bz",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-bz/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-cl",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-cl/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-cl",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-cl/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-co",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-co/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-co",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-co/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-cr",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-cr/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-cr",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-cr/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-ec",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-ec/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-ec",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-ec/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-es",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-es/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-es",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-es/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-ge",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-ge/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-gb",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-gb/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-hk",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-hk/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-hu",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-hu/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___hu-hu",
    path: indexi5j1BAjR1wMeta?.path ?? "/hu-hu/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-au",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-au/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-mx",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-mx/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-mx",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-mx/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-ni",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-ni/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-ni",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-ni/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-pa",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-pa/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-pa",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-pa/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-pe",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-pe/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-pe",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-pe/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-pl",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-pl/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___pl-pl",
    path: indexi5j1BAjR1wMeta?.path ?? "/pl-pl/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-pr",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-pr/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-pr",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-pr/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-pt",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-pt/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___pt-pt",
    path: indexi5j1BAjR1wMeta?.path ?? "/pt-pt/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___en-py",
    path: indexi5j1BAjR1wMeta?.path ?? "/en-py/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  },
  {
    name: indexi5j1BAjR1wMeta?.name ?? "tests___es-py",
    path: indexi5j1BAjR1wMeta?.path ?? "/es-py/tests",
    meta: indexi5j1BAjR1wMeta || {},
    alias: indexi5j1BAjR1wMeta?.alias || [],
    redirect: indexi5j1BAjR1wMeta?.redirect || undefined,
    component: () => import("/data/pages/tests/index.vue").then(m => m.default || m)
  }
]