<template>
  <button
    :type="props.type"
    @click="$emit('buttonClicked')"
    class="BaseButton-root"
    :class="{
      width,
      [`ak-${props.variant}`]: !!props.variant,
      [`ak-${props.size}`]: !!props.size,
      [`ak-${props.shape}`]: !!props.shape,
      [`font-${props.weight}`]: !!props.weight,
    }"
    :disabled="!!props.loading || props.disabled"
  >

    <span v-if="!!props.loading" class="BaseButtonSpinner-root"></span>
    <slot v-if="props.buttonAllignment == 'start'" />
    <span v-if="!!props.text">{{ props.text }}</span>
    <slot v-if="props.buttonAllignment == 'end'" />
  </button>
</template>

<script setup lang="ts">
type ButtonPropsVariant = "outlined" | "contained" | "text" | "link";

export interface ButtonProps {
  text?: string;
  type?: "button" | "submit" | "reset";
  width?: string;
  variant?: ButtonPropsVariant;
  size?: "small" | "medium" | "large";
  shape?: "square" | "rounded" | "pill";
  loading?: boolean;
  disabled?: boolean;
  weight?: "bold" | "semibold" | "normal",
  buttonAllignment?: "start" | "end";
}

const props = withDefaults(defineProps<ButtonProps>(), {
  width: "full",
  type: "button",
  variant: "contained",
  size: "medium",
  shape: "rounded",
  loading: false,
  disabled: false,
  weight: "bold",
  buttonAllignment: "start"
});
</script>

<style scoped>
.BaseButton-root {
  @apply relative select-none max-w-full flex gap-2 flex-nowrap rounded  focus:outline-none justify-center items-center duration-500 transition-all ease-in-out disabled:bg-slate-400 disabled:pointer-events-none disabled:opacity-60;

  border-color: currentColor;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover,
  &:focus {
    &:not(.ak-link) {
      @apply duration-500 transition-all;

      transform: translate(-1px, -2px);
      box-shadow: 0px 2px 4px 0px rgba(37, 55, 84, 0.5);
    }
  }
  
  &:active {
    &:not(.ak-link) {
      @apply duration-500 transition-all;
  
      box-shadow: 0px 1px 2px 0px rgba(37, 55, 84, 1);
      transform: translate(1px, -1px);  
    }
  }
  
  &:focus-visible {
    outline: none;
  }
}
.BaseButton-root.ak-outlined {
  @apply border-[1px] text-secondary hover:bg-white hover:text-[#AF387E] hover:border-[#DD8DBB] focus:bg-white focus:text-[#AF387E] focus:border-[#DD8DBB] focus-visible:bg-white focus-visible:text-[#AF387E] focus-visible:border-[#991E66] active:bg-[#F8EAF2] active:text-[#991E66] active:border-[#991E66];
}

.BaseButton-root.ak-contained {
  @apply bg-secondary text-white hover:bg-[#B73983] focus:bg-[#B73983] active:bg-[#AF387E] active:text-[#991E66] active:border-[#991E66];
}

.BaseButton-root.ak-text {
  @apply underline hover:shadow-none hover:bg-secondary/5 focus-visible:bg-secondary/5 hover:text-secondary focus-visible:text-secondary;
}

.BaseButton-root.ak-link {
  @apply text-secondary underline font-semibold hover:no-underline;
}

.BaseButton-root.ak-small {
  @apply text-sm;
  padding: 0.5em 0.75em;
}
.BaseButton-root.ak-medium {
  padding: 0.5em 1em;
}
.BaseButton-root.ak-large {
  @apply text-lg;
  padding: 0.5em 1.25em;
}

.BaseButton-root.ak-square {
  @apply rounded-none;
}
.BaseButton-root.ak-rounded {
  @apply rounded-md;
}
.BaseButton-root.ak-pill {
  border-radius: 2em;
}

.BaseButton-root span {
  @apply whitespace-normal;
}

/* Spinner */
.BaseButtonSpinner-root {
  @apply relative animate-spin border-2 border-b-transparent border-[currentColor] aspect-square w-[1em] flex-shrink-0;
  border-radius: 50%;
}
</style>
