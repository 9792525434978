import { jwtDecode } from "jwt-decode";
import type { RouteLocationNormalized } from "vue-router";
import Application from "~/utils/config";

const PROTECTED_ROUTES = [
  // Matches account routes except for /login, /signup and /password-recovery.
  /^(\/[a-z]{2}-[a-z]{2})?(\/account)(?!\/(login|signup|password-reset)\/?$).*/i,
];

function checkIsProtectedRoute(route: RouteLocationNormalized) {
  return PROTECTED_ROUTES.some((x) => x.test(route.fullPath));
}

function checkSessionTokenValidity() {
  const cookie = useCookie(Application.Cookies.authSession);
  const sessionToken = cookie.value;
  if (!sessionToken) return false;

  const jwtToken = jwtDecode(sessionToken);

  // Check token expiry
  if (new Date((jwtToken.exp ?? 0) * 1000) < new Date()) return false;

  // Token is valid.
  return true;
}

export default defineNuxtRouteMiddleware((to) => {
  const isProtectedRoute = checkIsProtectedRoute(to);
  if (!isProtectedRoute) return;

  const isSessionTokenValid = checkSessionTokenValidity();

  // Redirect user to login page if required.
  if (!isSessionTokenValid) return navigateTo(localeUri("/account/login"));
});
